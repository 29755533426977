import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import auth from './modules/auth'
import credits from './modules/credits'
import monomers from './modules/monomers'
import jobs from './modules/jobs'
import childJobs from './modules/childJobs'
import femJobs from './modules/femJobs'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage
})

const store = new Vuex.Store({
  modules: {
    auth,
    credits,
    monomers,
    jobs,
    childJobs,
    femJobs
  },
  plugins: [vuexLocal.plugin]
})

export default store
