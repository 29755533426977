<template>
    <v-container>
        <CreateJob />
    </v-container>
</template>

<script>
import CreateJob from '../components/create-job/CreateJob.vue'

export default {
    components: {
        CreateJob
    }
}
</script>
