import Vue from 'vue'
import restClient from '../../lib/restClient'
      
const fetchValue = async (what, commit) => { 
  const res = await restClient.get(`/v1/credits/${what}`, {responseType: 'text'})
  if (res.data && !isNaN(res.data)) {
    const value = parseFloat(res.data)
    commit('set', {what, value})
  }
}

export default {
  namespaced: true,
  state: {
    loading: true,
    softLimit: 0,
    hardLimit: 0,
    currentExecutionHours: 0
  },
  getters: {
    loading(state) {
      return state.loading
    },
    availableCredits(state) {
      return Math.floor(state.softLimit - state.currentExecutionHours)
    }
  },
  mutations: {
    loading(state, loading) {
      Vue.set(state, 'loading', loading)
    },
    set(state, {what, value}) {
      Vue.set(state, what, value)
    }
  },
  actions: {
    async load({ commit }) {
      commit('loading', true)

      const fetchSoftLimit = fetchValue('softLimit', commit)
      const fetchHardLimit = fetchValue('hardLimit', commit)
      const fetchCurrentEH = fetchValue('currentExecutionHours', commit)

      await fetchSoftLimit
      await fetchHardLimit
      await fetchCurrentEH

      commit('loading', false)
    },
    async update({ commit }) {
      await fetchValue('currentExecutionHours', commit)
    }
  }
}
