import Vue from 'vue'
import restClient from '../../lib/restClient'

window._eureqa_external = false

export default {
  namespaced: true,
  state: {
    loading: true,
    parentJobId: null,
    childJobs: {}
  },
  getters: {
    loading(state) {
      return state.loading
    },
    all(state) {
      let configs = []
      for (const id in state.childJobs) {
        configs.push(state.childJobs[id])
      }
      return configs
    },
    getById: state => id => {
      return state.childJobs[id]
    }
  },
  mutations: {
    loading(state, loading) {
      Vue.set(state, 'loading', loading)
    },
    parentJobId(state, parentJobId) {
      Vue.set(state, 'parentJobId', parentJobId)
    },
    clear(state) {
      Vue.set(state, 'childJobs', {})
    },
    add(state, job) {
      Vue.set(state.childJobs, job.id, job)
    }
  },
  actions: {
    async load({ commit, state }, id) {
      commit('loading', true)
      if (id !== state.parentJobId) {
        commit('clear')
      }
      let res = await restClient.get(`/v1/jobs/${id}/child-jobs`)
      if (res.data) {
        commit('clear')
        commit('parentJobId', id)
        for (const job of res.data) {
          commit('add', job)
        }
      }
      commit('loading', false)
    },
    terminate(_, id) {
      return restClient.post(`/v1/child-jobs/${id}/terminate`)
    },
    restart(_, id) {
      return restClient.post(`/v1/child-jobs/${id}/restart`, null, {
        params: {
          external: window._eureqa_external
        }
      })
    },
    refit(_, [id, data]) {
      return restClient.post(`/v1/child-jobs/${id}/refit`, data)
    }
  }
}
