<template>
  <v-dialog v-model="dialog" scrollable max-width="900px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small>mdi-plus</v-icon>
        Add Co-Polymers
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark flat>
        <v-toolbar-title>Add Co-Polymers</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <CopolymerEditor
            :copolymer="copolymer"
            :listOfMultiplicators="true"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="reject()">
          Cancel
        </v-btn>
        <v-btn color="primary" @click="apply()">
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__text {
    padding: 16px;
}
</style>

<script>
import CopolymerEditor from '../utils/CopolymerEditor.vue'

const INITIAL_COPOLYMER = [["",1], ["",1]];

export default {
    props: {
        value: [Array],
        multiple: Boolean
    },
    components: {
        CopolymerEditor
    },
    data() {
        return {
            id: null,
            copolymer: [...INITIAL_COPOLYMER],
            dialog: false,
        }
    },
    methods: {
        apply() {
            this.dialog = false
            // Create expanded list of co-polymers based on multiple multiplicators / ranges
            let copolymers = [[]]
            for (let monomerSpec of this.copolymer) {
                let monomer = monomerSpec[0]
                let multipliers = this.parseMultipliers(monomerSpec[1])
                let newCopolymers = []
                for (let multiplier of multipliers) {
                    let monomerPossiblyWithMultipler = multiplier == 1 ? monomer : [monomer, multiplier]
                    for (let existingCopolymer of copolymers) {
                        newCopolymers.push([...existingCopolymer, monomerPossiblyWithMultipler])
                    }
                }
                copolymers = newCopolymers
            }
            this.$emit(
                'input',
                copolymers
            )
            this.reset()
        },
        parseMultipliers(multipliersSpec) {
            if (typeof multipliersSpec === 'number')
                return [multipliersSpec]
            
            const numbers = []
            const specs = multipliersSpec.split(',');
            for (var spec of specs) {
                spec = spec.trim();
                const rangeSpecs = spec.split('-').map(rs => parseInt(rs));
                if (rangeSpecs.length == 1) {
                    const v = rangeSpecs[0]
                    numbers.push(v)
                } else if (rangeSpecs.length == 2) {
                    for (let v = rangeSpecs[0]; v <= rangeSpecs[1]; v++)
                        numbers.push(v)
                }
            }
            return numbers
        },
        reject() {
            this.dialog = false
            this.reset()
        },
        reset() {
            this.copolymer = [...INITIAL_COPOLYMER]
        }
    },
}
</script>
