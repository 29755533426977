<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-form ref="form" v-model="valid">
          <v-card class="elevation-12">
            <v-toolbar dark flat>
              <v-toolbar-title>Confirm Your Email Address</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-alert type="error" v-if="error">
                An error has occured, please check your confirmation code.
              </v-alert>
              <v-alert type="success" v-if="confirmationResent"
                >A new code has been sent to your email address.</v-alert
              >We have sent you a confirmation code to your email address. .
              Please enter the received code to confirm you email address.
              <v-text-field
                label="Your Email Address"
                name="code"
                prepend-icon="mdi-email"
                type="text"
                v-model="email"
                :rules="[rules.required]"
                autofocus
              />
              <v-text-field
                label="Your Confirmation Code"
                name="code"
                prepend-icon="mdi-numeric"
                type="text"
                v-model="code"
                :rules="[rules.required, rules.exact]"
                autofocus
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn @click.prevent="resendCode()" type="button"
                >Resend Code</v-btn
              >
              <v-btn
                color="primary"
                @click.prevent="confirm()"
                type="submit"
                :disabled="!valid"
                >Confirm</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auth from '../../lib/auth'

export default {
  data() {
    return {
      code: '',
      valid: false,
      error: false,
      confirmationResent: false,
      rules: {
        required: value => !!value || 'Required.',
        exact: v => v.length == 6 || 'Should be 6 characters'
      }
    }
  },
  methods: {
    async confirm() {
      this.error = false

      try {
        await auth.confirmSignUp({
          username: this.email,
          code: this.code
        })

        this.$router.push('/auth/login')
      } catch (e) {
        this.error = true
      }
    },
    async resendCode() {
      this.confirmationResent = false

      try {
        await auth.resendSignUp(this.email)
        this.confirmationResent = true
      } catch (e) {
        this.error = true
      }
    }
  },
  created() {
    this.email = this.$store.getters['auth/get'].email

    if (this.$route.params.code) {
      this.code = this.$route.params.code
    }
  }
}
</script>
