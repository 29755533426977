<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-form ref="form" v-model="valid">
          <v-card class="elevation-12">
            <v-toolbar dark flat>
              <v-toolbar-title>Set new Password</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-alert
                type="error"
                v-if="error"
              >Could not set new password. Please try again.</v-alert>
              You have to set a new password in order to login. Please specify
              your new password. The following rules will apply:
              <p>
              <ul>
                  <li>Min. 8 Characters</li>
                  <li>At least one number</li>
                  <li>At least a special character</li>
                  <li>At least one uppercase letter</li>
                  <li>At least one lowercase letter</li>
              </ul>
              </p>
              <v-text-field
                label="Your Email Address"
                name="email"
                prepend-icon="mdi-email"
                type="text"
                v-model="localEmail"
                :disabled=true
              />
              <v-text-field
                label="Your Current Password"
                name="currentPassword"
                prepend-icon="mdi-lock"
                type="password"
                v-model="localPassword"
                :disabled=true
              />

              <v-text-field
                label="Your new Password"
                name="newPassword"
                prepend-icon="mdi-lock"
                type="password"
                autofocus
                hint="At least 8 characters with the rules above"
                v-model="newPassword"
                :rules="[rules.required, rules.min]"
              />

              <v-text-field
                label="Confirm Your new Password"
                name="newPasswordConfirmation"
                prepend-icon="mdi-lock"
                type="password"
                hint="At least 8 characters with the rules above"
                v-model="newPasswordConfirmation"
                :rules="[rules.required, rules.min]"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
               <v-btn
                color="primary"
                @click.prevent="setNewPassword()"
                :disabled="!valid"
                type="submit"
              >Set new Password</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auth from '../../lib/auth'

export default {
  props: ['email', 'password'],
  data() {
    return {
      newPassword: '',
      newPasswordConfirmation: '',
      valid: false,
      error: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters'
      }
    }
  },

  methods: {
    async setNewPassword() {
      this.error = false

      if (this.newPassword !== this.newPasswordConfirmation || this.password === this.newPassword) {
        this.error = true
        return
      }

      try {
        await auth.setNewPassword({
          username: this.email,
          password: this.password,
          newPassword: this.newPassword
        })

        this.$router.push('/')
        this.$bus.$emit('user:update')
      } catch (e) {
        this.error = true
      }
    }
  },

  computed: {

      localPassword: {
      get: function () {
        return this.password
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },

    localEmail: {
      get: function () {
        return this.email
      },
      set: function (value) {
        this.$emit('input', value)
      }
    },
  }
}
</script>
