<template>
  <v-card :loading="loading ? 'secondary' : false">
    <v-toolbar dark flat>
      <v-toolbar-title>
        <v-icon left>mdi-heat-wave</v-icon>
        Polymer Jobs
      </v-toolbar-title>

      <DialogJobInfo v-model="infoDialog" :data="jobInfo" />

      <DialogJobResults v-model="resultsDialog" :data="jobResults" />

      <v-spacer></v-spacer>

      <v-btn icon @click="load()">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn icon @click="createNewJob()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-row class="filter">
        <v-switch v-model="filterCreatedByMe" label="Show only jobs created by me" class="mr-12" />
        <v-text-field v-model="filterName" label="Search for job by name" clearable />
      </v-row>

      <v-data-table
        :headers="headers"
        :items="all"
        :items-per-page="5"
        :sort-by="['createdAt']"
        :sort-desc="[true]"
        :class="{loading: loading}"
      >
        <template v-slot:item.name="{ item }">
          <!-- <a @click="info(item)">
            {{ item.name }}
          </a> -->
          <router-link :to="{ name: 'childJobs', params: { id: item.id } }">
            {{ item.name }}
          </router-link>
        </template>

        <template v-slot:item.state="{ item }">
          <span v-if="item.state === 'CREATED'">
            <v-icon>mdi-timer-sand</v-icon>Created
          </span>
          <span v-if="item.state === 'STARTED' || item.state === 'FINALIZING'">
            <v-icon>mdi-run</v-icon>Running
          </span>
          <span v-if="item.state === 'FINISHED'">
            <v-icon>mdi-flag-checkered</v-icon>Finished
          </span>
          <span v-if="item.state === 'ABORTED'">
            <v-icon>mdi-cancel</v-icon>Aborted
          </span>
          <span v-if="item.state === 'QUEUED'">
            <v-icon>mdi-tray-full</v-icon>Queued
          </span>
          <span v-if="item.state === 'FAILED'">
            <v-icon>mdi-alert</v-icon>Failed
          </span>
        </template>

        <template v-slot:item.action="{ item }">
          <!-- Only if job is done: showing the results dialog -->
          <action-button v-if="item.state === 'FINISHED' || item.state === 'FAILED'"
              @click="showJobResults(item)"
              icon="mdi-clipboard-list"
              color="green"
          >
            <span>Results</span>
          </action-button>

          <!-- Always: showing the info dialog -->
          <action-button
              @click="showJobInfo(item)"
              icon="mdi-information"
              color="blue"
          >
            <span>Info</span>
          </action-button>

          <!-- TODO -->
          <action-button v-if="item.state === 'FINISHED' || item.state === 'FAILED' || item.state === 'ABORTED'"
              @click="notYetImplemented()"
              icon="mdi-refresh-circle"
              color="orange"
          >
            <span>Restart / Cancel / Delete<br/>(Not yet implemented)</span>
          </action-button>
          <action-button v-else
              @click="notYetImplemented()"
              icon="mdi-stop-circle"
              color="red"
          >
            <span>Restart / Cancel / Delete<br/>(Not yet implemented)</span>
          </action-button>
        </template>

        <template v-slot:no-data>
          <template v-if="loading"><LoadingIndicator /> Loading...</template>
          <template v-if="!loading">No jobs available</template>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
/* BEGIN -- Could be refactored into generic list component */

/* Add some margin to the state icon */
table .v-icon {
  margin-right: 0.2em;
}

/* Remove margin of card since main content is a data-table which has already its own margin in cells */
.v-card__text {
  padding: 0;

  .filter {
    margin: 0;
    padding: 0 16px;
  }
}

/* Reduce the padding-right of the last column because the action buttons would otherwise look as if they had some margin */
.v-data-table ::v-deep td:last-child {
  padding-right: 8px; /* instead of 16px */
}

.loading {
  opacity: .5;
}
/* END -- Could be refactored into generic list component */
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingIndicator from '../utils/LoadingIndicator.vue'
import DialogJobInfo from '../job-dialogs/DialogJobInfo.vue'
import DialogJobResults from '../job-dialogs/DialogJobResults.vue'
import ActionButton from '../utils/ActionButton.vue'

export default {
  components: {
    LoadingIndicator,
    DialogJobInfo,
    DialogJobResults,
    ActionButton
  },
  data() {
    return {
      query: '',
      showJobName: process.env.VUE_APP_SUBDOMAIN !== '',
      infoDialog: false,
      resultsDialog: false,
      headers: [
        { text: 'Name', value: 'name', align: 'left', width: '25%' },
        { text: 'Total Jobs', value: 'totalChildJobs', width: '10%' },
        { text: 'Successful Jobs', value: 'successfulChildJobs', width: '10%' },
        {
          text: 'Unsuccessful Jobs',
          value: 'unsuccessfulChildJobs',
          width: '10%'
        },
        { text: 'State', value: 'state', width: '10%' },
        { text: 'Created At', value: 'createdAt' },
        { text: 'Actions', value: 'action', align: 'right', sortable: false }
      ],
      job: {},
      jobInfo: {},
      jobResults: {},
      starting: false,
      valid: false,
      error: false,
      rules: {
        required: value => !!value || 'Required.'
      }
    }
  },
  methods: {
    ...mapActions('jobs', ['load', 'create', 'delete', 'changeFilter']),
    async notYetImplemented(item) {
      item; // currently unused
      alert('Not yet implemented')
    },
    async deleteJob(item) {
      if (confirm('Do you really want to delete this job?')) {
        await this.delete(item.id)
      }
    },
    async showJobInfo(item) {
      this.infoDialog = true
      this.jobInfo = item
    },
    async showJobResults(item) {
      this.$router.push({ path: '/results/' + item.id })
    },
    createNewJob() {
      this.$router.push({ path: '/jobs' })
    }
  },
  computed: {
    ...mapGetters('jobs', ['all', 'loading', 'getFilter']),
    filterName: {
      get() { return this.getFilter.name },
      set(value) { this.changeFilter({name: value}) }
    },
    filterCreatedByMe: {
      get() { return this.getFilter.createdByMe },
      set(value) { this.changeFilter({createdByMe: value}) }
    }
  },
  async created() {
    this.load()
  }
}
</script>
