<template>
    <v-dialog v-model="visible" scrollable max-width="700px">
        <v-card>
            <v-toolbar dark flat>
                <v-toolbar-title>
                    Details for {{ data.result.key }} results
                </v-toolbar-title>
            </v-toolbar>
            
            <v-card-text class="results">
                <v-expansion-panels accordion multiple>
                    <v-expansion-panel>
                        <v-expansion-panel-header>Simulation properties</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-data-table dense
                                :hide-default-footer="properties.length < 10"
                                :headers="[ { text: 'Property', align: 'begin', value: 'key',   sortable: false },
                                            { text: 'Value',    align: 'end',   value: 'value', sortable: false },
                                            { text: 'Unit',     align: 'begin', value: 'unit',  sortable: false } ]"
                                :items="properties"
                            >
                            </v-data-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="data.result.key == 'cooling'">
                        <v-expansion-panel-header>Refitting (adjust temperature ranges)</v-expansion-panel-header>

                        <v-expansion-panel-content>
                            <p>With this tool, you can adjust the temperature thresholds T_a and T_b which are used
                            to do a linear fitting and then interesect those lines to find the glass transition
                            temperature T_g.</p>

                            <!--
                                TEXT FOR ALL SIMTIMES:
                            -->
                            <p v-if="data.job.configuration.coolingSimtimes.length > 1">
                                <b>Important note:</b> Although this dialog represents the results for a single
                                value of simtime, the refitting will be carried out on all simtimes of this job
                                ({{all_simtimes_text}} ns).
                            </p>

                            <p>The fields T_a and T_b are pre-filled with the current values. The linear fitting
                            will then be carried out on the intervals [100K, T_a] and [T_b, 700K].</p>

                            <!--
                                TEXT FOR SELECTIVE SIMTIMES:

                            <p>For the simtimes, you can select a subset of what have been simulated in this job.
                            Since this dialog was opened for a specific simtime value, this is pre-filled in the
                            following form, but feel free to add multiple values there, separated by comma.</p>

                            <p>The fields T_a and T_b are pre-filled with the current values for <b>this</b>
                            simtime currently opened in this dialog ({{data.result.simTime}} ns). The linear fitting
                            will then be carried out on the intervals [100K, T_a] and [T_b, 700K].</p>
                            -->

                            <v-form ref="refit_form">
                                <v-container class="pa-0">
                                    <v-row>
                                        <v-col cols="7" class="py-4">
                                            <label for="T_a">
                                                Upper bound for lower temperature range (T_a), in K:
                                            </label>
                                        </v-col>
                                        <v-col cols="5">
                                            <v-text-field name="T_a" type="number" class="ma-0"
                                                v-model="refit.tempBelowLimit"
                                                hide-details="auto"
                                                single-line dense
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="7" class="py-4">
                                            <label for="T_b">
                                                Lower bound for upper temperature range (T_b), in K:
                                            </label>
                                        </v-col>
                                        <v-col cols="5">
                                            <v-text-field name="T_b" type="number" class="ma-0"
                                                v-model="refit.tempAboveLimit"
                                                hide-details="auto"
                                                single-line dense
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <!--
                                    <v-row>
                                        <v-col cols="7" class="py-4">
                                            <label for="simtimes">
                                                Simtimes to re-analyze (comma-separated), in ns:
                                            </label>
                                        </v-col>
                                        <v-col cols="5">
                                            <v-text-field name="simtimes" type="text" class="ma-0"
                                                v-model="refit.simtimes"
                                                hide-details="auto"
                                                single-line dense
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    -->

                                    <v-row class="justify-end pa-3">
                                        <v-btn @click="doRefit()" color="primary">
                                            <v-icon>mdi-play</v-icon>Start
                                        </v-btn>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <pre class="job-id">Job-ID: {{ data.job.id }}<br/>Result-ID: {{ data.result.id }}</pre>

                <v-spacer></v-spacer>

                <v-btn text @click="visible = false">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style lang="scss" scoped>
.v-dialog > .v-card > {
    .v-card__text {
        padding: 16px;
    }
    .v-card__title,
    .v-card__actions {
        padding-left: 16px;
    }
    .v-card__actions > .job-id {
        font-size: 60%;
        font-weight: normal;
        opacity: 0.5;
    }
}

/* Put the expansion panels inside the card's content without margins
   to realize a "flat" look of the expansion panels */
.v-dialog--scrollable > .v-card > {
    .v-card__text.results {
        padding: 0;
    }
    .v-card__text.results > .v-expansion-panels {
        margin: 0;
        border-radius: 0;
    }

    .v-card__text.results > .v-expansion-panels > {
        .v-expansion-panel::before {
            display: none;
        }
        .v-expansion-panel:hover {
            background: rgba(#808080, 0.1);
        }
        .v-expansion-panel--active > .v-expansion-panel-header--active {
            font-weight: 700;
        }

        /* Also adjust the expansion panels internal horizontal paddings
        to those of the card's default paddings (16px instead of 24px) */
        .v-expansion-panel > {
            .v-expansion-panel-header
            ,.v-expansion-panel-content ::v-deep .v-expansion-panel-content__wrap {
                padding-left: 16px;
                padding-right: 16px;

                /* Undo padding of content which by itself adds again padding. */
                > .v-data-table {
                    margin-left: -16px;
                    margin-right: -16px;
                    max-width: none;
                }
            }
        }
    }
}
</style>

<script>
import { mapActions } from 'vuex'

export default {
    props: {
        value: Boolean,
        data: Object,
    },
    data() {
        return {
            refit: {
                tempBelowLimit: 0,
                tempAboveLimit: 0,
                simtimes: ""
            }
        }
    },
    computed: {
        visible: {
            get() { return this.value },
            set(visible) { this.$emit('input', visible) }
        },
        properties() {
            if (this.data.result && this.data.result.properties) {
                // Convert properties from the following object-notation
                //  { "temp": { "value": 1, "unit": "K" }, ... }
                // into this array-notation:
                //  [ { "key": "temp", "value": 1, "unit": "K" }, ... ]
                const properties = Object.entries(this.data.result.properties)
                        .map(([key, data]) => ({key, ...data}))

                // Also add simtime and temp, if it exists
                if (this.data.result.temp) {
                    properties.unshift({
                        key: 'temp',
                        value: this.data.result.temp,
                        unit: 'K'
                    })
                }
                if (this.data.result.simTime) {
                    properties.unshift({
                        key: 'simtime',
                        value: this.data.result.simTime,
                        unit: 'ns'
                    })
                }

                return properties
            } else {
                return []
            }
        },
        all_simtimes_text() {
            return this.data.job.configuration.coolingSimtimes.join(', ')
        }
    },
    methods: {
        ...mapActions({refitChildJob: 'childJobs/refit'}),

        doRefit() {
            if (this.$refs.refit_form.validate()) {
                this.refitChildJob([this.data.job.id, {
                    tempBelowLimit: Number.parseFloat(this.refit.tempBelowLimit),
                    tempAboveLimit: Number.parseFloat(this.refit.tempAboveLimit),
                    simtimes: this.refit.simtimes.split(',').map(v => Number.parseFloat(v)),
                }])
            }
        }
    },
    watch: {
        data: {
            deep: true,
            handler: function() {
                this.refit.tempBelowLimit = this.data.result.properties.T_a.value
                this.refit.tempAboveLimit = this.data.result.properties.T_b.value
                // use this for selective simtimes:  this.refit.simtimes = '' + this.data.result.simTime
                this.refit.simtimes = this.data.job.configuration.coolingSimtimes.join(',')
            }
        }
    }
}
</script>
