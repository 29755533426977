var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading ? 'secondary' : false}},[_c('v-toolbar',{attrs:{"dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v("Results for Job "+_vm._s(_vm.parentJob.name))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.downloads($event)}}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1),_c('v-card-text',[_c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0 pt-4",staticStyle:{"border-right":"2px solid rgba(0, 0, 0, 0.12)"},attrs:{"cols":"2"}},[_c('h5',{staticClass:"mx-4 mt-0 mb-0",staticStyle:{"font-size":"12px","line-height":"1.5","height":"31px","padding-top":"6px"}},[_vm._v(" Computed property (y-axis) ")]),_c('v-list',{staticClass:"key ma-0 pt-0 pb-0",attrs:{"three-line":""}},[_c('v-list-item-group',{staticClass:"key",model:{value:(_vm.key),callback:function ($$v) {_vm.key=$$v},expression:"key"}},[_vm._l((_vm.keys),function(k,index){return [_c('div',{key:index},[_c('v-divider'),_c('v-list-item',{attrs:{"value":k.key,"disabled":!k.available}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(k.simulationTitle))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(k.computedProperty.title)+" ["+_vm._s(k.computedProperty.unit)+"]")])],1)],1)],1)]})],2)],1)],1),_c('v-col',{staticClass:"ma-0 pa-0 pt-4",attrs:{"cols":"10"}},[_c('v-data-table',{attrs:{"dense":"","hide-default-footer":true,"headers":[
              {
                text: 'Parameter',
                align: 'begin',
                value: 'text',
                sortable: false
              },
              {
                text: 'Use in plot as ...',
                align: 'begin',
                value: 'usage',
                sortable: false
              },
              {
                text: 'Select specific value(s)',
                align: 'begin',
                value: 'values',
                sortable: false
              }
            ],"sort":false,"items":_vm.variations},scopedSlots:_vm._u([{key:"item.text",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" ")]}},{key:"item.usage",fn:function(ref){
            var item = ref.item;
return [(item.values.length > 1)?_c('v-chip-group',{model:{value:(item.usage),callback:function ($$v) {_vm.$set(item, "usage", $$v)},expression:"item.usage"}},[_c('v-chip',{attrs:{"value":"x-axis"}},[_vm._v("x-axis")]),_c('v-chip',{attrs:{"value":"colors"}},[_vm._v("color")]),_c('v-chip',{attrs:{"value":"styles"}},[_vm._v("style")])],1):(item.values.length == 1)?_c('span',{staticClass:"no-usage single-value"},[_vm._v(" – single value – ")]):_c('span',{staticClass:"no-usage no-value"},[_vm._v(" – not available for "+_vm._s(_vm.key)+" – ")])]}},{key:"item.values",fn:function(ref){
            var item = ref.item;
return [_c('v-chip-group',{attrs:{"multiple":""},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}},_vm._l((item.values),function(value,index){return _c('v-chip',{key:index,class:{
                    'usage-x-axis': item.usage === 'x-axis',
                    'usage-colors': item.usage === 'colors',
                    'usage-styles': item.usage === 'styles',
                    'usage-specific': item.usage === undefined
                  },style:(item.selected.indexOf(value) !== -1
                      ? item.usage == 'colors'
                        ? {
                            background:
                              _vm.colors[
                                item.selected.indexOf(value) % _vm.colors.length
                              ]
                          }
                        : item.usage == 'styles'
                        ? {
                            'border-style':
                              _vm.styles[
                                item.selected.indexOf(value) % _vm.styles.length
                              ].style,
                            'border-width':
                              _vm.styles[
                                item.selected.indexOf(value) % _vm.styles.length
                              ].width + 'px',
                            padding:
                              '0 ' +
                              (8 -
                                _vm.styles[
                                  item.selected.indexOf(value) % _vm.styles.length
                                ].width) +
                              'px'
                          }
                        : {}
                      : {}),attrs:{"value":value,"disabled":item.values.length == 1}},[_vm._v(_vm._s(value))])}),1)]}}])}),_c('v-divider'),_c('div',{staticClass:"px-4 pt-4",staticStyle:{"height":"300px"}},[(_vm.selectedForXAxis != -1)?_c('apexchart',{attrs:{"type":"line","height":"100%","options":_vm.chartOptions,"series":_vm.chartSeries}}):_c('div',{staticStyle:{"padding":"20px 0"}},[_c('p',[_vm._v("No chart can be plotted for the above configuration.")]),_c('p',[_vm._v(" From the list of parameters, select at least one for the "),_c('strong',[_vm._v("x-axis")]),_vm._v(". ")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }