<template>
  <v-card :loading="loading ? 'secondary' : false">
    <v-toolbar dark flat>
      <v-toolbar-title>
        <v-icon left>mdi-heat-wave</v-icon>
        Polymer Systems in Job {{ parentJob.name }}
      </v-toolbar-title>

      <DialogJobInfo v-model="infoDialog" :data="childJobInfo" />

      <DialogJobResults v-model="resultsDialog" :data="childJobResults" />

      <v-spacer></v-spacer>

      <v-btn icon @click="loadChildJobs()">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-row class="filter">
        <v-text-field v-model="query" label="Search for job by name" clearable />
      </v-row>

      <v-snackbar v-model="snackbar" timeout="5000" bottom>
        {{ snackbarText }}
      </v-snackbar>

      <v-data-table
        :headers="headers"
        :items="all"
        :items-per-page="10"
        :sort-by="['createdAt']"
        :sort-desc="[true]"
        :search="query"
        :custom-filter="searchByName"
        :class="{loading: loading}"
      >
        <template v-slot:item.state="{ item }">
          <span class="state">
            <span v-if="item.state === 'CREATED'">
              <v-icon>mdi-timer-sand</v-icon>Created
            </span>
            <span v-if="item.state === 'STARTED'">
              <v-icon>mdi-run</v-icon>Running ({{ progressPercent(item) }})
            </span>
            <span v-if="item.state === 'FINISHED'">
              <v-icon>mdi-flag-checkered</v-icon>Finished
            </span>
            <span v-if="item.state === 'ABORTED'">
              <v-icon>mdi-cancel</v-icon>Aborted
            </span>
            <span v-if="item.state === 'QUEUED'">
              <v-icon>mdi-tray-full</v-icon>Queued
            </span>
            <span v-if="item.state === 'FAILED'">
              <v-icon>mdi-alert</v-icon>Failed
            </span>
          </span>
        </template>

        <template v-slot:item.createdAt="{ item }">
          {{ formatDate(item.createdAt) }}
        </template>
        <template v-slot:item.startedAt="{ item }">
          {{ formatDate(item.startedAt) }}
        </template>
        <template v-slot:item.stoppedAt="{ item }">
          {{ formatDate(item.stoppedAt) }}
        </template>
        <template v-slot:item.duration="{ item }">
          <span :class="{'duration-running': item.stoppedAt == null}">
            {{ formatDuration(duration(item)) }}
          </span>
        </template>

        <template v-slot:item.action="{ item }">
          <!-- Only if job is done: showing the results dialog -->
          <action-button v-if="item.state === 'FINISHED' || item.state === 'FAILED'"
              @click="showJobResults(item)"
              icon="mdi-clipboard-list"
              color="green"
          >
            <span>Results</span>
          </action-button>

          <!-- Always: showing the info dialog -->
          <action-button
              @click="showJobInfo(item)"
              icon="mdi-information"
              color="blue"
          >
            <span>Info</span>
          </action-button>

          <!-- Always: action regarding execution (either restart or cancel, depending on current state) -->
          <action-button v-if="item.state === 'FINISHED' || item.state === 'FAILED' || item.state === 'ABORTED'"
              @click="restartJob(item)"
              icon="mdi-refresh-circle"
              color="orange"
          >
            <span>Restart job</span>
          </action-button>
          <action-button v-else
              @click="cancelJob(item)"
              icon="mdi-stop-circle"
              color="red"
          >
            <span>Cancel job</span>
          </action-button>
        </template>

        <template v-slot:no-data>
          <template v-if="loading"><LoadingIndicator /> Loading...</template>
          <template v-if="!loading">No jobs available</template>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
/* BEGIN -- Could be refactored into generic list component */

/* Add some margin to the state icon */
.state .v-icon {
  margin-right: 0.2em;
}

/* Remove margin of card since main content is a data-table which has already its own margin in cells */
.v-card__text {
  padding: 0;

  .filter {
    margin: 0;
    padding: 0 16px;
  }
}

/* Reduce the padding-right of the last column because the action buttons would otherwise look as if they had some margin */
.v-data-table ::v-deep td:last-child {
  padding-right: 8px; /* instead of 16px */
}

.loading {
  opacity: .5;
}
/* END -- Could be refactored into generic list component */



.duration-running {
  color: gray;
  font-style: italic;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingIndicator from '../utils/LoadingIndicator.vue'
import DialogJobInfo from '../job-dialogs/DialogJobInfo.vue'
import DialogJobResults from '../job-dialogs/DialogJobResults.vue'
import ActionButton from '../utils/ActionButton.vue'

export default {
  components: {
    LoadingIndicator,
    DialogJobInfo,
    DialogJobResults,
    ActionButton
  },
  data() {
    return {
      intervals: [],
      currentTime: Date.now(),
      query: '',
      infoDialog: false,
      resultsDialog: false,
      headers: [
        { text: 'Name', value: 'name', align: 'begin' },
        { text: 'State', value: 'state', width: '10%' },
        { text: 'Created At', value: 'createdAt' },
        { text: 'Started At', value: 'startedAt' },
        { text: 'Stopped At', value: 'stoppedAt' },
        { text: 'Duration', value: 'duration', align: 'end' },
        { text: 'Actions', value: 'action', align: 'end', sortable: false }
      ],
      jobId: '',
      snackbar: false,
      snackbarText: '',
      childJobInfo: {},
      childJobResults: {},
      rules: {
        required: value => !!value || 'Required.'
      }
    }
  },
  methods: {
    ...mapActions('childJobs', ['load', 'terminate', 'restart']),
    ...mapActions({loadJobs: 'jobs/load'}),

    async showJobInfo(item) {
      this.infoDialog = true
      this.childJobInfo = item
    },
    async showJobResults(item) {
      this.resultsDialog = true
      this.childJobResults = item
    },
    async cancelJob(item) {
      if (!confirm('Do you really want to cancel this child job?')) {
        return
      }
      try {
        await this.terminate(item.id)
        this.loadChildJobs()
        this.snackbarText = 'Child Job successfully canceled.'
      } catch {
        this.snackbarText = 'Child Job could not be canceled.'
      }
      this.snackbar = true
    },
    async restartJob(item) {
      if (!confirm('Do you really want to restart this child job?')) {
        return
      }
      try {
        await this.restart(item.id)
        this.loadChildJobs()
        this.snackbarText = 'Child Job successfully restarted.'
      } catch {
        this.snackbarText = 'Child Job could not be restarted.'
      }
      this.snackbar = true
    },
    searchByName(value, search, item) {
      return (
        item &&
        search != null &&
        item.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
      )
    },
    progress(item) {
      if (item.timeCurrentPhases == 0 && item.timeUpcomingPhases == 0) {
        return 0
      } else if (item.stoppedAt) {
        return 1
      } else {
        const t = (this.currentTime - Date.parse(item.startedAt)) / 1000
        const progress = t / (Math.max(t + 1, item.timeCurrentPhases) + item.timeUpcomingPhases)
        return Math.min(progress, 0.999)
      }
    },
    progressPercent(item) {
      const progress = this.progress(item)
      return (progress * 100).toFixed(1) + ' %'
    },
    formatDate(date) {
      if (date) {
        return new Date(date).toLocaleString()
      } else {
        return '-'
      }
    },
    duration(item) {
      if (item.startedAt) {
        const start = Date.parse(item.startedAt)
        const end = item.stoppedAt ? Date.parse(item.stoppedAt) : this.currentTime
        return (end - start) / 1000
      } else {
        return null
      }
    },
    formatDuration(duration_seconds) {
      if (duration_seconds == null) {
        return '-'
      } else {
        const duration_minuntes = Math.ceil(duration_seconds / 60)
        const diff_h = Math.floor(duration_minuntes / 60)
        const diff_min = duration_minuntes % 60
        return diff_h + 'h ' + diff_min + 'min'
      }
    },
    updateCurrentTime() {
      // This will trigger an update of computed properties / methods which depend on currentTime
      this.currentTime = Date.now()
    },
    loadChildJobs() {
      if (this.jobId != '') {
        // Also load (parent) jobs if the current jobId is not found in the store
        // (Needed for example when starting the browser tab in this view)
        if (this.jobById(this.jobId) === undefined) {
          this.loadJobs()
        }
        this.load(this.jobId)
      }
    }
  },
  watch: {
    all() {
      for (const job of this.all) {
        // For storting by duration column
        job.duration = this.duration(job)
      }
    }
  },
  computed: {
    ...mapGetters('childJobs', ['all', 'loading']),
    ...mapGetters({jobById: 'jobs/getById'}),
    parentJob: function() {
      return this.jobById(this.jobId)
    }
  },
  async created() {
    this.jobId = this.$route.params.id
    this.loadChildJobs()

    this.intervals = [
      setInterval(this.updateCurrentTime, 1000),
      setInterval(this.loadChildJobs, 3 * 60 * 1000)
    ]
  },
  destroyed () {
    for (const interval of this.intervals) {
      clearInterval(interval)
    }
  }
}
</script>
