<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-form ref="form" v-model="valid">
          <v-card class="elevation-12">
            <v-toolbar dark flat>
              <v-toolbar-title>Please log in</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-alert type="error" v-if="error"
                >Wrong Username or Password</v-alert
              >

              <v-text-field
                label="Your Email Address"
                name="email"
                prepend-icon="mdi-email"
                type="text"
                v-model="email"
                :rules="[rules.required]"
                autofocus
              />

              <v-text-field
                label="Your Password"
                name="password"
                prepend-icon="mdi-lock"
                type="password"
                :rules="[rules.required]"
                v-model="password"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                @click.prevent="login()"
                :disabled="!valid"
                type="submit"
                >Login</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
        <br />
        <div class="auth__links">
          <router-link :to="{ path: '/auth/reset-password' }"
            >Forgot password?</router-link
          >
          <!--| <router-link :to="{ path: '/auth/register' }">Register</router-link> -->
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auth from '../../lib/auth'

export default {
  data() {
    return {
      email: '',
      password: '',
      valid: false,
      error: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters'
      }
    }
  },
  methods: {
    async login() {
      this.error = false

      try {
        await auth.signIn({
          username: this.email,
          password: this.password
        })

        if (
          this.$route.query.redirect &&
          this.$route.query.redirect.indexOf('/') === 0
        ) {
          this.$router.push(this.$route.query.redirect)
        } else {
          this.$router.push('/')
        }

        this.$bus.$emit('user:update')
      } catch (e) {
        if (e === 'SET_NEW_PASSWORD') {
          this.$router.push({
            name: 'newPassword',
            params: { email: this.email, password: this.password }
          })
        } else {
          this.error = true
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.auth__links
  text-align: center
  font-size: 0.9em

  a
    text-decoration: none
</style>
