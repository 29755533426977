<template>
  <v-container>
    <v-row align="start">
      <v-col>
        <h1>Your Account</h1>
      </v-col>
    </v-row>
    <v-row>
      <!-- v-col>
        <Attributes />
      </v-col -->
      <v-col>
        <Password />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Attributes from '../components/account/Attributes.vue'
import Password from '../components/account/Password.vue'

export default {
  components: { 
    // Attributes, 
    Password 
  }
}
</script>
