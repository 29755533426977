<template>
    <v-dialog v-model="visible" scrollable max-width="700px">
        <v-card>
            <v-toolbar dark flat>
                <v-toolbar-title>
                    Configuration of "{{ data.name }}"
                </v-toolbar-title>
            </v-toolbar>

            <v-card-text>
                <pre>{{ data.configuration }}</pre>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <pre class="job-id">ID: {{ data.id }}</pre>

                <v-spacer></v-spacer>

                <v-btn @click="clone(data)">
                    <v-icon>mdi-content-duplicate</v-icon>Clone
                </v-btn>
                <v-btn text @click="visible = false">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style lang="scss" scoped>
.v-dialog > .v-card > {
    .v-card__text {
        padding: 16px;
    }
    .v-card__title,
    .v-card__actions {
        padding-left: 16px;
    }
    .v-card__actions > .job-id {
        font-size: 60%;
        font-weight: normal;
        opacity: 0.5;
    }
}

/* Fix bug with padding-bottom inside of overflow container */
.v-dialog--scrollable > .v-card > {
    .v-card__text {
        padding-bottom: 0;
    }
    .v-card__text > :last-child {
        margin-bottom: 16px;
    }
}
</style>

<script>
export default {
    props: {
        value: Boolean,
        data: Object
    },
    computed: {
        visible: {
            get() { return this.value },
            set(visible) { this.$emit('input', visible) }
        },
        isChildJob() {
            return 'parentJobId' in this.data
        },
        isFemJob() {
            return 'gridSize' in this.data.configuration
        }
    },
    methods: {
        clone(item) {
            const routeName = this.isFemJob ? "fem-jobs" : "jobs"
            const queryKey = this.isChildJob ? "from-child-job" : "from-job"
            let query = {}
            query[queryKey] = item.id
            this.$router.push({ path: '/' + routeName, query })
        }
    }
}
</script>
