import Vue from 'vue'
import restClient from '../../lib/restClient'

window._eureqa_external = true

export default {
  namespaced: true,
  state: {
    loading: true,
    filter: {
      name: null,
      createdByMe: false
    },
    // TODO: pagination
    jobs: {},

    // Room for improvement: separate estimation into dedicated module
    estimationLoading: false,
    estimation: {}
  },
  getters: {
    loading(state) {
      return state.loading
    },
    getFilter(state) {
      return state.filter;
    },
    all(state) {
      let configs = []
      for (const id in state.jobs) {
        configs.push(state.jobs[id])
      }
      return configs
    },
    getById: state => id => {
      return state.jobs[id]
    },
    estimationLoading(state) {
      return state.estimationLoading
    },
    estimation(state) {
      if (window._eureqa_external) {
        const factor = 3
        return {
            ...state.estimation,
            seconds_longest_child_job: state.estimation.seconds_longest_child_job * factor
        }
      } else {
        return state.estimation
      }
    }
  },
  mutations: {
    loading(state, loading) {
      Vue.set(state, 'loading', loading)
    },
    filter(state, filter) {
      Vue.set(state, 'filter', filter);
    },
    filterProperty(state, changeWithinFilter) {
      for (const key in changeWithinFilter)
        Vue.set(state.filter, key, changeWithinFilter[key]);
    },
    clear(state) {
      Vue.set(state, 'jobs', {})
    },
    add(state, job) {
      Vue.set(state.jobs, job.id, job)
    },
    del(state, id) {
      Vue.delete(state.jobs, id)
    },
    estimationLoading(state, estimationLoading) {
      Vue.set(state, 'estimationLoading', estimationLoading)
    },
    estimation(state, estimation) {
      Vue.set(state, 'estimation', estimation)
    },
    clearEstimation(state) {
      Vue.set(state, 'estimation', {})
    }
  },
  actions: {
    async load({ commit, state }) {
      commit('loading', true)
      const filterSnapshot = JSON.stringify(state.filter)
      // Pagination: Currently, although the REST endpoint already supports pagination, we still implement pagination client-side, so we need to get all results that match the filter
      const params = {
        page: 0,
        size: 2000 // max page size supported by Spring Boot backend
      }
      // Filter:
      if (state.filter.name !== null && state.filter.name !== '') {
        params.name = state.filter.name
      }
      if (state.filter.createdByMe) {
        params.createdBy = 'me'
      }
      let res = await restClient.get(`/v1/jobs/search`, { params })
      // Abort processing result if there has been another call to load() with a different filter in the meantime
      if (JSON.stringify(state.filter) === filterSnapshot) {
        if (res.data && res.data.content) {
          commit('clear')
          for (const job of res.data.content) {
            commit('add', job)
          }
        }
        commit('loading', false)
      }
    },
    async changeFilter({ commit, dispatch }, changeFilter) {
      commit('filterProperty', changeFilter)
      dispatch('load')
    },
    async create({ commit }, job) {
      let res = await restClient.post(`/v1/jobs`, job, {
        params: {
          external: window._eureqa_external
        }
      })
      commit('add', res.data)
    },
    async delete({ commit }, id) {
      await restClient.delete(`/v1/jobs/${id}`)
      commit('del', id)
    },
    async estimate({ commit }, job) {
      commit('estimationLoading', true)
      try {
        let res = await restClient.post(`/v1/jobs/estimate`, job)
        commit('estimation', res.data)
      } catch {
        commit('clearEstimation')
      }
      commit('estimationLoading', false)
    },
    async clearEstimation({ commit }) {
      commit('clearEstimation')
    }
  }
}
