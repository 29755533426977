<template>
  <v-card>
    <v-toolbar dark flat>
      <v-toolbar-title>
        <v-icon left>mdi-heat-wave</v-icon>
        Create New Polymer Job
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$router.push({ path: '/' })">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-form ref="nameForm" v-model="nameValid" on>
      <v-card-text>
        <v-row>
          <v-col cols="5">
            <v-text-field
              label="Name"
              name="name"
              type="text"
              v-model="name"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-card-text>
    </v-form>

    <v-divider></v-divider>

    <v-form ref="configForm" v-model="configValid" on>
      <v-card-title>Polymer System</v-card-title>
      <v-card-subtitle>Define the material to be simulated</v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col cols="5">
            <CopolymerEditor
              :disabled="variations.monomer"
              :copolymer="configuration.monomer"
              :simplifiedSingleMonomerInput="true"
            />
          </v-col>
          <v-col cols="2">
            <v-switch
              label="Variations"
              name="variations.monomer"
              v-model="variations.monomer"
            />
          </v-col>
          <v-col cols="5" v-if="variations.monomer">
            <v-textarea
                  label="Variations of monomers / co-polymers (one monomer / co-polymer per line)"
                  name="configuration.monomerVariations"
                  type="text"
                  v-model="configuration.monomerVariations"
                  :rules="[rules.required, rules.uniquePerLine]"
            />
            <v-row>
              <v-spacer/>
              <v-col cols="auto"
                ><MonomerSearch
                  :multiple="true"
                  :addMode="true"
                  v-model="selectedMonomers"
              /></v-col>
              <v-col cols="auto"
                ><AddCopolymersDialog
                  :listOfMultiplicators="true"
                  v-model="selectedCopolymers"
              /></v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5">
            <v-text-field
              label="Number of fibers"
              name="configuration.fibers"
              type="number"
              :disabled="variations.fibers"
              v-model.number="configuration.fibers"
              :rules="variations.fibers ? [] : [rules.required]"
            />
          </v-col>
          <v-col cols="2">
            <v-switch
              label="Variations"
              name="variations.fibers"
              v-model="variations.fibers"
            />
          </v-col>
          <v-col cols="5" v-if="variations.fibers">
            <v-textarea
              label="Variations of numbers of fibers (one value per line)"
              name="configuration.fibersVariations"
              type="text"
              v-model="configuration.fibersVariations"
              :rules="[rules.required, rules.oneNumberPerLine, rules.uniquePerLine]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5">
            <v-text-field
              :label="'Average fiber length' + (haveCopolymers ? ' (repetitions of the whole co-polymer sequence)' : '')"
              name="configuration.averageFiberLength"
              type="number"
              :disabled="variations.averageFiberLength"
              v-model.number="configuration.averageFiberLength"
              :rules="variations.averageFiberLength ? [] : [rules.required, rules.validFiberLength]"
            />
          </v-col>
          <v-col cols="2">
            <v-switch
              label="Variations"
              name="variations.averageFiberLength"
              v-model="variations.averageFiberLength"
            />
          </v-col>
          <v-col cols="5" v-if="variations.averageFiberLength">
            <v-textarea
              label="Variations of average fiber lengths (one value per line)"
              name="configuration.averageFiberLengthVariations"
              type="text"
              v-model="configuration.averageFiberLengthVariations"
              :rules="[rules.required, rules.oneNumberPerLine, rules.validFiberLengthPerLine, rules.uniquePerLine]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5">
            <v-text-field
              label="Dispersity (PDI)"
              name="configuration.dispersity"
              type="number"
              :disabled="variations.dispersity"
              v-model.number="configuration.dispersity"
              :rules="variations.dispersity ? [] : [rules.required]"
            />
          </v-col>
          <v-col cols="2">
            <v-switch
              label="Variations"
              name="variations.dispersity"
              v-model="variations.dispersity"
            />
          </v-col>
          <v-col cols="5" v-if="variations.dispersity">
            <v-textarea
              label="Variations of dispersity values (one value per line)"
              name="configuration.dispersityVariations"
              type="text"
              v-model="configuration.dispersityVariations"
              :rules="[rules.required, rules.oneNumberPerLine, rules.uniquePerLine]"
            />
          </v-col>
        </v-row>

        <h3 class="mt-4" style="opacity: .8">External Plasticiers (optional)</h3>

        <v-row>
          <v-col cols="5">
            <v-text-field
              label="Plasticiser added to the system"
              name="configuration.plasticiser"
              type="text"
              :disabled="variations.plasticiser"
              v-model="configuration.plasticiser"
              :rules="[]"
            />
          </v-col>
          <v-col cols="2">
            <v-switch
              label="Variations"
              name="variations.plasticiser"
              v-model="variations.plasticiser"
            />
          </v-col>
          <v-col cols="5" v-if="variations.plasticiser">
            <v-textarea
              label="Variations of plasticisers (one value per line)"
              name="configuration.plasticiserVariations"
              type="text"
              v-model="configuration.plasticiserVariations"
              :rules="[]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5">
            <v-text-field
              label="Number of molecules of plasticiser"
              name="configuration.numberOfPlasticisers"
              type="number"
              :disabled="!havePlasticiser || variations.numberOfPlasticisers"
              v-model.number="configuration.numberOfPlasticisers"
              :rules="
                !havePlasticiser ? []
                : variations.numberOfPlasticisers ? []
                : [rules.required, rules.validNumberOfPlasticisers]
              "
            />
            <p :class="{'faint': !havePlasticiser}" style="font-size: 90%; line-height: 1.2">
              Note that the amount of plasticiser added to the system should not outweigh the
              amount of polymer (maximum 10-15 wt%) to give meaningful simulation results.
              Also, they are currently not being considered in the cost estimation (see below).
            </p>
          </v-col>
          <v-col cols="2">
            <v-switch
              label="Variations"
              name="variations.numberOfPlasticisers"
              :disabled="!havePlasticiser"
              v-model="variations.numberOfPlasticisers"
            />
          </v-col>
          <v-col cols="5" v-if="variations.numberOfPlasticisers">
            <v-textarea
              label="Variations of number of molecules of plasticiser (one value per line)"
              name="configuration.numberOfPlasticisersVariations"
              type="text"
              :disabled="!havePlasticiser"
              v-model="configuration.numberOfPlasticisersVariations"
              :rules="
                !havePlasticiser ? []
                : [rules.required, rules.oneNumberPerLine, rules.validNumberOfPlasticisersPerLine, rules.uniquePerLine]"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-title>Properties</v-card-title>
      <v-card-subtitle>Define which simulations should be ran</v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col>
            <v-switch
              label="Density"
              name="configuration.density"
              v-model="configuration.density"
              :rules="[rules.densityAlwaysTrue]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            <v-switch
              label="Cooling"
              name="configuration.cooling"
              v-model="configuration.cooling"
            />
          </v-col>
          <v-col v-if="configuration.cooling" cols="4">
            <v-text-field
              label="Simulation times (in ns, separated by comma)"
              name="configuration.coolingSimtimes"
              type="text"
              v-model="configuration.coolingSimtimes"
              :rules="[rules.required, rules.numbersSeparatedByComma, rules.uniqueSeparatedByComma]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            <v-switch
              label="Stress Strain"
              name="configuration.stressStrain"
              v-model="configuration.stressStrain"
            />
          </v-col>
          <v-col v-if="configuration.stressStrain" cols="4">
            <v-text-field
              label="Simulation times (in ns, separated by comma)"
              name="configuration.stressStrainSimtimes"
              type="text"
              v-model="configuration.stressStrainSimtimes"
              :rules="[rules.required, rules.numbersSeparatedByComma, rules.uniqueSeparatedByComma]"
            />
            <v-text-field
              label="Temperatures (in K, separated by comma)"
              name="configuration.stressStrainTemps"
              type="text"
              v-model="configuration.stressStrainTemps"
              :rules="[rules.required, rules.numbersSeparatedByComma, rules.uniqueSeparatedByComma]"
            />
            <v-switch
              label="Hardness"
              name="configuration.stressStrainHardness"
              v-model="configuration.stressStrainHardness"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-progress-linear
        v-if="estimationLoading"
        absolute indeterminate color="secondary"
      ></v-progress-linear>

      <v-card-title>
        Summary

        <v-progress-linear
          :active="estimationLoading"
          :indeterminate="estimationLoading"
          absolute top
        ></v-progress-linear>
      </v-card-title>

      <v-card-text>
        <v-alert type="info" v-if="success">
          Your job has been scheduled for computation.
        </v-alert>
        <v-alert type="error" v-if="error">
          Could not create job. Please try again later.
        </v-alert>

        <ul v-if="estimation.hasOwnProperty('credits_total') || estimationLoading" :class="{faint: estimationLoading}">
          <li>
            This job simulates
            <strong>{{formatNumberOfChildJobs(estimation.n_child_jobs)}}</strong>
            different polymer system{{estimation.n_child_jobs > 1 ? 's' : ''}}.
          </li>
          <li>
            This job will cost you an estimated ammount of
            <strong>{{formatCredits(estimation.credits_total)}}</strong>
            credit{{estimation.credits_total > 1 ? 's' : ''}} in total.
          </li>
          <li>
            This job will take an estimated duration of
            <strong>{{formatDuration(estimation.seconds_longest_child_job)}}</strong>
            <span v-if="estimation.n_child_jobs > 1">&nbsp;(largest polymer system)</span>.
          </li>
        </ul>
        <span v-else>
          Once the input in the form above is valid, the estimated resources of executing the job are displayed here.
        </span>
      </v-card-text>
    </v-form>

    <v-card-actions>
      <v-spacer />

      <v-tooltip top left>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
                color="primary"
                :disabled="!configValid || !nameValid"
                @click="save()"
            >
              <v-icon>mdi-play</v-icon>
              Create &amp; Run
            </v-btn>
          </div>
        </template>
        <span v-if="!configValid">
          Some parameter seems to be missing or invalid.
        </span>
        <span v-else-if="!estimation.hasOwnProperty('credits_total')">
          The backend complains about this setup.
          <br/>
          This can for example happen if you used a monomer which doesn't exist, so please check that.
        </span>
        <span v-else-if="!nameValid">
          Job name is missing.
        </span>
        <span v-else>
          You're ready to go!
        </span>
      </v-tooltip>

    </v-card-actions>
  </v-card>
</template>

<style scoped lang="scss">
  /* Compress the appearance of the summary section's content and the card's action buttons */
  .v-form > .v-card__text:last-child > :last-child  {
    /* Reserve space on the right for the (only) action button of the card */
    padding-right: 160px;
  }
  .v-card__actions {
    /* Amount of compression */
    margin-top: -32px;
  }

  /* Compress the padding of each field, which was introduced by wrapping them in v-row + v-col */
  .v-card__text ::v-deep .col {
    padding-top: 0;
    padding-bottom: 0;
  }

  .faint {
    opacity: 0.5;
  }
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import CopolymerEditor from '../utils/CopolymerEditor.vue'
import MonomerSearch from '../utils/MonomerSearch.vue'
import AddCopolymersDialog from './AddCopolymersDialog.vue'
import { monomerSpecToString, monomerSpecFromString, monomerSpecIsCopolymer } from '../utils/monomerSpecFormat'


function uniqueArray(a) {
  return a.length === new Set(a).size;
}


export default {
  components: {
    CopolymerEditor,
    MonomerSearch,
    AddCopolymersDialog
  },
  data() {
    return {
      name: '',
      nameValid: false,
      numberOfJobs: 0,
      selectedMonomers: '',
      selectedCopolymers: [],
      configuration: {  // FIXME: Find a better name / merge with 'variations'. This holds the models for the form inputs.
        monomer: [["",1]],
        monomerVariations: "",
        plasticiser: "",
        plasticiserVariations: "",
        density: true
      },
      variations: {  // FIXME: Find a better name / merge with 'configuration'. This holds the models for the toggle switches.
        plasticiser: false
      },
      config: {},  // FIXME: Find a better name. This is the configuration as in REST API.
      configValid: false,
      success: false,
      error: false,
      rules: {
        required: value => !!value || 'This field is required.',
        validFiberLength: value => value != null && parseInt(value) >= 3 || 'The fiber length must be at least 3.',
        validNumberOfPlasticisers: value => value != null && parseInt(value) >= 10 && parseInt(value) <= 200 || 'Currently, the number of plasticisers must be beween 10 and 200.',
        numbersSeparatedByComma: value => value != null && value.split(',').every(v => {
          v = v.trim()
          if (v === '') return true;
          else return !isNaN(v)
        }) || 'Please only enter numbers separated by comma.',
        oneNumberPerLine: value => value != null && value.split('\n').every(v => {
          v = v.trim()
          if (v === '') return true;
          else return !isNaN(v)
        }) || 'Please enter one number per line (each line resulting in one variation).',
        validFiberLengthPerLine: value => value != null && value.split('\n').every(v => {
          v = v.trim()
          if (v === '') return true;
          else return parseInt(v) >= 3
        }) || 'Each of the fiber lengths must be at least 3.',
        validNumberOfPlasticisersPerLine: value => value != null && value.split('\n').every(v => {
          v = v.trim()
          if (v === '') return true;
          else return parseInt(v) >= 10 && parseInt(v) <= 200
        }) || 'Currently, the number of plasticisers must be beween 10 and 200 for every job.',
        uniquePerLine: value => value != null
          && uniqueArray(value.split('\n').map(v => v.trim()))
          || 'Duplicate values found.',
        uniqueSeparatedByComma: value => value != null
          && uniqueArray(value.split(',').map(v => v.trim()))
          || 'Duplicate values found.',
        densityAlwaysTrue: value => value === true || 'In the current version of EureQa, the density is always required to be computed.'
      },
      debounceTimer: null
    }
  },
  methods: {
    ...mapActions('jobs', [
      'create',
      'update',
      'estimate',
      'clearEstimation'
    ]),
    split(str, sepeartor, conversionFunction = null) {
      let res = []
      for (let item of (str || '').trim().split(sepeartor)) {
        item = item.trim()
        if (item.length > 0) {
          if (conversionFunction) {
            item = conversionFunction(item)
          }
          res.push(item)
        }
      }
      return res
    },
    initFromConfig(config) {
      // Monomer
      this.variations.monomer = 'monomerVariations' in config
      if (this.variations.monomer) {
        this.configuration.monomerVariations =
          config.monomerVariations
            .map(monomerSpecToString)
            .join('\n')
      } else {
        this.configuration.monomer = config.monomer.map(monomer => {
          if (Array.isArray(monomer)) {
            return monomer
          } else {
            return [monomer, 1]
          }
        })
      }

      // Fibers
      this.variations.fibers = 'fibersVariations' in config
      if (this.variations.fibers) {
        this.configuration.fibersVariations =
          config.fibersVariations.join('\n')
      } else {
        this.configuration.fibers = config.fibers
      }

      // Avg fiber length
      this.variations.averageFiberLength =
        'averageFiberLengthVariations' in config
      if (this.variations.averageFiberLength) {
        this.configuration.averageFiberLengthVariations =
          config.averageFiberLengthVariations.join('\n')
      } else {
        this.configuration.averageFiberLength =
          config.averageFiberLength
      }

      // Dispersity
      this.variations.dispersity = 'dispersityVariations' in config
      if (this.variations.dispersity) {
        this.configuration.dispersityVariations =
          config.dispersityVariations.join('\n')
      } else {
        this.configuration.dispersity = config.dispersity
      }

      // Config has plasticisers?
      if ('plasticiser' in config || 'plasticiserVariations' in config) {
        // Plasticiser
        this.variations.plasticiser = 'plasticiserVariations' in config
        if (this.variations.plasticiser) {
          this.configuration.plasticiserVariations =
            config.plasticiserVariations.join('\n')
        } else {
          this.configuration.plasticiser = config.plasticiser
        }

        // Number of Plasticisers
        this.variations.numberOfPlasticisers = 'numberOfPlasticisersVariations' in config
        if (this.variations.numberOfPlasticisers) {
          this.configuration.numberOfPlasticisersVariations =
            config.numberOfPlasticisersVariations.join('\n')
        } else {
          this.configuration.numberOfPlasticisers = config.numberOfPlasticisers
        }
      }

      // Density
      this.configuration.density = config.density

      // Cooling
      this.configuration.cooling = config.cooling
      if (this.configuration.cooling) {
        this.configuration.coolingSimtimes =
          config.coolingSimtimes
            .join(',')
      }

      // Stress Strain / Hardness
      this.configuration.stressStrain = config.stressStrain
      if (this.configuration.stressStrain) {
        this.configuration.stressStrainSimtimes =
          config.stressStrainSimtimes.join(',')
        this.configuration.stressStrainTemps =
          config.stressStrainTemps.join(',')
        this.configuration.stressStrainHardness =
          config.stressStrainHardness
      }

      // Create new objects for Vue to apply the new properties properly
      this.configuration = Object.assign({}, this.configuration)
      this.variations = Object.assign({}, this.variations)
    },
    prepareConfig() {
      let result = {}

      // Monomer
      if (this.variations.monomer) {
        result.monomerVariations = this.split(
          this.configuration.monomerVariations,
          '\n',
          monomerSpecFromString
        )
      } else {
        result.monomer = this.configuration.monomer.map(monomer => {
          if (monomer[1] == 1) {
            return monomer[0]
          } else {
            return monomer
          }
        })
      }

      // Fibers
      if (this.variations.fibers) {
        result.fibersVariations = this.split(
          this.configuration.fibersVariations,
          '\n',
          parseInt
        )
      } else {
        result.fibers = this.configuration.fibers
      }

      // Avg fiber length
      if (this.variations.averageFiberLength) {
        result.averageFiberLengthVariations = this.split(
          this.configuration.averageFiberLengthVariations,
          '\n',
          parseInt
        )
      } else {
        result.averageFiberLength = this.configuration.averageFiberLength
      }

      // Dispersity
      if (this.variations.dispersity) {
        result.dispersityVariations = this.split(
          this.configuration.dispersityVariations,
          '\n',
          parseFloat
        )
      } else {
        result.dispersity = this.configuration.dispersity
      }

      if (this.havePlasticiser) {
        // Plasticiser
        if (this.variations.plasticiser) {
          result.plasticiserVariations = this.split(
            this.configuration.plasticiserVariations,
            '\n'
          )
        } else {
          result.plasticiser = this.configuration.plasticiser
        }

        // Number of Plasticisers
        if (this.variations.numberOfPlasticisers) {
          result.numberOfPlasticisersVariations = this.split(
            this.configuration.numberOfPlasticisersVariations,
            '\n'
          )
        } else {
          result.numberOfPlasticisers = this.configuration.numberOfPlasticisers
        }
      }

      // Density
      result.density = this.configuration.density

      // Cooling
      result.cooling = this.configuration.cooling
      if (this.configuration.cooling) {
        result.coolingSimtimes = this.split(
          this.configuration.coolingSimtimes,
          ',',
          parseFloat
        )
      }

      // Stress Strain / Hardness
      result.stressStrain = this.configuration.stressStrain
      if (this.configuration.stressStrain) {
        result.stressStrainSimtimes = this.split(
          this.configuration.stressStrainSimtimes,
          ',',
          parseFloat
        )
        result.stressStrainTemps = this.split(
          this.configuration.stressStrainTemps,
          ',',
          parseFloat
        )
        result.stressStrainHardness = this.configuration.stressStrainHardness
      }
      console.log(JSON.stringify(result))
      return result
    },
    async save() {
      this.success = false
      this.error = false

      try {
        await this.create({
          name: this.name,
          configuration: this.prepareConfig()
        })
        this.success = true
      } catch (e) {
        this.error = true
      }
    },
    calculateJobs() {
      const config = this.prepareConfig()
      this.config = config

      let variants = {
        monomers: (config.monomerVariations || [1]).length,
        fibers: (config.fibersVariations || [1]).length,
        averageFiberLength: (config.averageFiberLengthVariations || [1]).length,
        dispersity: (config.dispersityVariations || [1]).length,
        plasticiser: (config.plasticiserVariations || [1]).length,
        numberOfPlasticisers: (config.numberOfPlasticisersVariations || [1]).length,
      }

      this.numberOfJobs =
        variants.monomers *
        variants.fibers *
        variants.averageFiberLength *
        variants.dispersity *
        variants.plasticiser *
        variants.numberOfPlasticisers
    },
    formatNumberOfChildJobs(numberOfChildJobs) {
      if (isNaN(numberOfChildJobs)) {
        return '...'
      } else {
        return numberOfChildJobs
      }
    },
    formatCredits(credits) {
      if (isNaN(credits)) {
        return '...'
      } else {
        return Math.ceil(credits)
      }
    },
    formatDuration(seconds) {
      if (isNaN(seconds)) {
        return '...'
      } else {
        let minutes = Math.ceil(seconds / 60)
        const hours = Math.floor(minutes / 60)
        minutes %= 60
        return (hours > 0 ? (hours + ' hour' + (hours > 1 ? 's' : '') + ' ') : '')
            + (minutes + ' minute' + (minutes > 1 ? 's' : ''))
      }
    },
    updateEstimation() {
      this.calculateJobs()
      if (this.configValid) {
        this.estimate({
          configuration: this.prepareConfig()
        })
      } else {
        this.clearEstimation()
      }
    },
    configurationChanged() {
      // debounce call to updateEstimation():
      clearTimeout(this.debounceTimer)
      this.debounceTimer = setTimeout(() => {
        this.debounceTimer = null
        this.updateEstimation()
      }, 500)
    }
  },
  watch: {
    selectedMonomers(valueFromDialog) {
      if (!this.configuration.monomerVariations)
        this.configuration.monomerVariations = ''

      this.configuration.monomerVariations =
        this.configuration.monomerVariations.trim() + '\n'
        + valueFromDialog.join('\n')
    },
    selectedCopolymers(valueFromDialog) {
      if (!this.configuration.monomerVariations)
        this.configuration.monomerVariations = ''

      this.configuration.monomerVariations =
        this.configuration.monomerVariations.trim() + '\n'
        + valueFromDialog
            .map(monomerSpecToString)
            .join('\n')
    },
    configuration: {
      handler() {
        this.configurationChanged()
      },
      deep: true
    },
    variations: {
      handler() {
        this.configurationChanged()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      jobById: 'jobs/getById',
      estimation: 'jobs/estimation',
      estimationLoading: 'jobs/estimationLoading',
      childJobById: 'childJobs/getById',
    }),
    haveCopolymers() {
      if (this.variations.monomer) {
        return monomerSpecFromString(this.configuration.monomerVariations).some(monomerSpecIsCopolymer)
      } else {
        return monomerSpecIsCopolymer(this.configuration.monomer)
      }
    },
    havePlasticiser() {
      if (this.variations.plasticiser) {
        return this.configuration.plasticiserVariations.split('\n').some(s => s.trim().length > 0)
      } else {
        return this.configuration.plasticiser.trim().length > 0
      }
    }
  },
  async created() {
    this.clearEstimation()

    let job = 'from-job' in this.$route.query
            ? this.jobById(this.$route.query['from-job'])
            : 'from-child-job' in this.$route.query
            ? this.childJobById(this.$route.query['from-child-job'])
            : undefined

    if (job !== undefined) {
      let config = job.configuration
      this.initFromConfig(config)
    }
  }
}
</script>
