import Vue from 'vue'
import VueRouter from 'vue-router'

import auth from '../lib/auth'

// application pages:
import PageDashboard from '../pages/PageDashboard.vue'
import PageCreateMonomer from '../pages/PageCreateMonomer.vue'
import PageCreateJob from '../pages/PageCreateJob.vue'
import PageCreateFemJob from '../pages/PageCreateFemJob.vue'
import PageChildJobs from '../pages/PageChildJobs.vue'
import PageJobResults from '../pages/PageJobResults.vue'
import PageAccount from '../pages/PageAccount.vue'

// auth & password handling:
import PageRegister from '../pages/auth/PageRegister.vue'
import PageLogin from '../pages/auth/PageLogin.vue'
import PageConfirm from '../pages/auth/PageConfirm.vue'
import PageResetPassword from '../pages/auth/PageResetPassword.vue'
import PageNewPassword from '../pages/auth/PageNewPassword.vue'

// static pages:
import PageImprint from '../pages/static/PageImprint.vue'
import PageNotFound from '../pages/static/PageNotFound.vue'


Vue.use(VueRouter)

function requireAuth(to, from, next) {
  auth
    .isLoggedIn()
    .then(next)
    .catch(() => {
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath }
      })
    })
}

async function logout(to, from, next) {
  await auth.signOut()
  Vue.bus.$emit('user:update')
  next('/auth/login')
}

export default new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    // application pages:
    { path: '/', component: PageDashboard, beforeEnter: requireAuth },
    { path: '/monomers/:id?', component: PageCreateMonomer, beforeEnter: requireAuth },
    { path: '/jobs/:id?', component: PageCreateJob, beforeEnter: requireAuth },
    { path: '/results/:id?', component: PageJobResults, beforeEnter: requireAuth },
    { path: '/child-jobs/:id?', component: PageChildJobs, beforeEnter: requireAuth, name: 'childJobs' },
    { path: '/fem-jobs/:id?', component: PageCreateFemJob, beforeEnter: requireAuth, name: 'femJobs' },
    { path: '/account', component: PageAccount, beforeEnter: requireAuth },

    // auth & password handling:
    { path: '/auth/register', component: PageRegister },
    { path: '/auth/confirm', component: PageConfirm },
    { path: '/auth/confirm/:code', component: PageConfirm },
    { path: '/auth/login', component: PageLogin },
    { path: '/auth/logout', component: PageLogin, beforeEnter: logout },
    { path: '/auth/reset-password', component: PageResetPassword },
    { path: '/auth/new-password', component: PageNewPassword, props: true, name: 'newPassword' },

    // static pages:
    { path: '/imprint', component: PageImprint },
    { path: '*', component: PageNotFound }  // fallback
  ]
})
