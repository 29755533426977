<template>
    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on"
                    @click="buttonClicked($event)"
                    :href="href"
                    icon
                    :color="color"
            >
                <v-icon>{{icon}}</v-icon>
            </v-btn>
        </template>
        <slot></slot>
    </v-tooltip>
</template>

<style scoped>
/* Emphasize the hover effect of action buttons in case they are placed within
   a data table, since the row itself already has a hover effect */
.v-data-table .v-btn:not(.v-btn--text):not(.v-btn--outlined):hover::before {
    opacity: .15;
}

/* Desaturate non-hovered button icons */
.v-btn {
    filter: saturate(40%);
    opacity: 0.8;
}
.v-btn:hover {
    filter: saturate(60%);
    opacity: 1;
}
</style>

<script>
export default {
    props: {
        icon: String,
        color: String,
        href: String
    },
    methods: {
        buttonClicked(event) {
            this.$emit('click', [event])
        }
    }
}
</script>
