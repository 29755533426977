<template>
  <v-app id="app">
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app>
      <v-btn icon large>
        <v-avatar size="32px" item>
          <v-img
            :src="require('./assets/logo.png')"
            alt="HQS"
            @click="$router.push({ path: '/' })"
          />
        </v-avatar>
      </v-btn>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">EureQa</span>
      </v-toolbar-title>

      <v-tabs align-with-title background-color="transparent">
        <v-tab :to="{ path: '/' }">
          <v-icon left>mdi-monitor-dashboard</v-icon>
          Dashboard
        </v-tab>
        <v-tab :to="{ path: '/monomers' }">
          <v-icon left>mdi-atom</v-icon>
          New Monomer
        </v-tab>
        <v-tab :to="{ path: '/jobs' }">
          <v-icon left>mdi-heat-wave</v-icon>
          New Polymer Job
        </v-tab>
        <v-tab :to="{ path: '/fem-jobs' }">
          <v-icon left>mdi-cube</v-icon>
          New FEM Job
        </v-tab>
      </v-tabs>

      <v-spacer />

      <v-menu bottom v-if="isLoggedIn">
        <template v-slot:activator="{ on }">
          <v-btn class="account" text v-on="on">
            <v-icon>mdi-account</v-icon>
            {{ user.email }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item :to="{ path: '/account' }">
            <v-list-item-title>
              <v-icon left>mdi-account</v-icon>My Account
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>
              <v-icon left>mdi-logout</v-icon>Log Out
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <span v-if="isLoggedIn" class="credits">
          <v-icon>mdi-wallet-outline</v-icon>&nbsp;
          <template v-if="loading">Loading...</template>
          <template v-if="!loading"><strong>{{availableCredits}}</strong> credits</template>
      </span>
    </v-app-bar>
    <v-main>
      <template v-if="$route.matched.length">
        <router-view></router-view>
      </template>
    </v-main>
    <v-footer color="gray justify-center" app>
      <span class="footer__small">
        &copy; {{ new Date().getFullYear() }} HQS Quantum Simulations -
        <router-link :to="{ path: '/imprint' }">Imprint</router-link>
      </span>
    </v-footer>
  </v-app>
</template>

<script>
import auth from './lib/auth'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      subdomain: process.env.VUE_APP_SUBDOMAIN,
      updateCreditsTimer: null,
      user: {},
      isLoggedIn: false
    }
  },
  methods: {
    refresh() {
      auth.getUser().then(user => {
        this.user = user
      })

      auth.isLoggedIn().then(() => {
        this.isLoggedIn = true
        this.$store.dispatch('credits/load')
      }).catch(() => {
        this.showAccount = false
      })
    },
    logout() {
      auth.signOut().then(() => {
        this.isLoggedIn = false
        this.showAccount = false
        sessionStorage.clear()
        this.$router.push({ path: '/auth/login' })
      })
    }
  },
  computed: {
    ...mapGetters('credits', ['availableCredits', 'loading'])
  },
  created() {
    document.title = process.env.VUE_APP_TITLE_PREFIX + document.title
    this.$bus.$on('user:update', this.refresh)
    this.refresh()
  },
  mounted() {
    this.updateCreditsTimer = window.setInterval(() => {
      this.$store.dispatch('credits/update')
    }, 5 * 60 * 1000)
  },
  unmounted() {
    window.clearInterval(this.updateCreditsTimer)
  }
}
</script>

<style scoped>
.v-btn.account {
  text-transform: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
}

.credits {
  white-space: nowrap;
}

.footer__small {
  font-size: 0.8em;
}
</style>

<!-- Apply theme colors globally -->
<style lang="scss">
#app {
  background-color: var(--v-background-base);
}

.theme--light,
.theme--dark {
  .v-sheet,
  .v-app-bar.v-toolbar.v-sheet {
    background: var(--v-sheet-base);
  }

  .v-toolbar.v-sheet,
  .v-snack__wrapper {
    background: var(--v-title-base);
  }

  .v-data-table,
  .v-expansion-panels .v-expansion-panel {
    background: inherit;
  }

  .v-overlay > .v-overlay__scrim {
    background-color: #777 !important;
  }
}

.theme--light {
  .v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: rgba(#000, 0.05);
  }
}
.theme--dark {
  .v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: rgba(#fff, 0.05);
  }
  .v-sheet.v-card:not(.v-sheet--outlined),
  .v-dialog {
    outline: 1px solid #333;
  }
}
</style>
