<template>
    <div>
        <v-container >
            <JobResults />
        </v-container>
    </div>
</template>

<script>
import JobResults from '../components/result-plots/JobResults.vue'

export default {
    components: {
        JobResults
    }
}
</script>
