<template>
  <div>
    <v-row>
      <v-col class="monomer-list">
        <v-row v-for="(monomer, index) in copolymer" :key="index">
          <v-col>
            <v-text-field
              :label="
                copolymer.length > 1 || !simplifiedSingleMonomerInput
                  ? 'Monomer ' + (index + 1)
                  : 'Monomer'
              "
              type="text"
              :disabled="disabled"
              v-model="monomer[0]"
              :rules="disabled ? [] : [rules.required]"
            />
          </v-col>
          <v-col
            md="auto"
            :hidden="disabled"
            class="wrap-button-next-to-text-field"
          >
            <MonomerSearch :multiple="false" v-model="monomer[0]" />
          </v-col>
          <v-col
            v-if="copolymer.length > 1 || !simplifiedSingleMonomerInput"
            :md="listOfMultiplicators ? 6 : 3"
          >
            <v-text-field
              v-if="listOfMultiplicators"
              label="Multiplicator(s) (separated by comma or range with hyphen)"
              v-model="monomer[1]"
              :rules="
                disabled ? [] : [rules.required, rules.validMultiplicatorList]
              "
            />
            <v-text-field
              v-else
              label="Multiplicator"
              type="number"
              :disabled="disabled"
              v-model.number="monomer[1]"
              :rules="
                disabled ? [] : [rules.required, rules.validMultiplicator]
              "
            />
          </v-col>
          <v-col
            md="auto"
            v-if="copolymer.length > 1 || !simplifiedSingleMonomerInput"
          >
            <v-btn
              :disabled="disabled || copolymer.length == 1"
              small
              class="baseline-adjusted-button"
              @click="deleteMonomer(index)"
            >
              <v-icon small>mdi-delete</v-icon>
              Delete monomer
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        md="auto"
        v-if="copolymer.length == 1 && simplifiedSingleMonomerInput"
      >
        <v-btn
          :disabled="disabled"
          small
          class="baseline-adjusted-button"
          @click="addMonomer()"
        >
          Create co-polymer
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="copolymer.length > 1 || !simplifiedSingleMonomerInput">
      <v-col
        md="9"
        v-if="!simplifiedSingleMonomerInput && copolymer.length == 1"
      >
        Since this co-polymer contains only one monomer, it is technically not a
        co-polymer. While we consider this a valid input, you probably want to
        add another monomer for creating a real co-polymer material.
      </v-col>
      <v-spacer />
      <v-col md="auto">
        <v-btn :disabled="disabled" small @click="addMonomer()">
          <v-icon small>mdi-plus</v-icon>
          Add another monomer
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped lang="scss">
.col.wrap-button-next-to-text-field {
  padding-top: 16px !important;
  padding-left: 0;
  margin-left: -10px;
}

.monomer-list .row:not(:last-child) {
  margin-bottom: -16px;
}

.baseline-adjusted-button.v-size--small {
  margin-top: 18px;
}

/* Compress the padding of each field, which was introduced by wrapping them in v-row + v-col */
.col {
  padding-top: 0;
  padding-bottom: 0;
}
</style>

<script>
import MonomerSearch from './MonomerSearch.vue'

export default {
  props: {
    copolymer: Array,
    disabled: Boolean,
    simplifiedSingleMonomerInput: {
      type: Boolean,
      default: false
    },
    listOfMultiplicators: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MonomerSearch
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || 'This field is required.',
        validMultiplicator: (value) =>
          (value != null &&
            Number.isInteger(parseFloat(value)) &&
            parseInt(value) >= 0) ||
          'Expected positive integer.',
        validMultiplicatorList: (value) => {
          if (value == null) return true
          if (value === '') return 'This field is required.'
          if (typeof value === 'number') return true
          if (value.indexOf('..') > -1)
            return 'Type multiple values separated with comma (don\'t use "..").'
          var specs = value.split(',')
          for (var spec of specs) {
            spec = spec.trim()
            if (spec === '') return 'Type multiple values separated with comma.'
            var rangeSpecs = spec.split('-')
            if (rangeSpecs.length > 2)
              return 'Each range can only contain one dash.'
            for (var rangeSpec of rangeSpecs) {
              rangeSpec = rangeSpec.trim()
              if (rangeSpec === '')
                return 'Each range should be in "from-to" notation.'
              if (
                !(
                  Number.isInteger(parseFloat(value)) &&
                  parseInt(rangeSpec) >= 0
                )
              )
                return 'Each number should be a positive integer or 0.'
            }
          }
          return true
        }
      }
    }
  },

  methods: {
    addMonomer() {
      let copolymer = this.copolymer
      copolymer.push(['', 1])
      this.localCopolymer = copolymer
    },

    deleteMonomer(index) {
      let copolymer = this.copolymer
      copolymer.splice(index, 1)

      // If only one monomer left, change its multiplicator to 1:
      if (copolymer.length == 1) {
        copolymer[0][1] = 1
      }
      this.localCopolymer = copolymer
    }
  },

  computed: {
    localCopolymer: {
      get: function () {
        return this.copolymer
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
