<template>
  <v-card :loading="loading ? 'secondary' : false">
    <v-toolbar dark flat>
      <v-toolbar-title>
        <v-icon left>mdi-atom</v-icon>
        Monomers
      </v-toolbar-title>

      <!-- DialogJobInfo v-model="infoDialog" :data="childJobInfo" />

      <DialogJobResults v-model="resultsDialog" :data="childJobResults" / -->

      <v-spacer></v-spacer>

      <v-btn icon @click="load()">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn icon @click="create()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-row class="filter">
        <v-text-field v-model="query" label="Search for monomer by name" clearable />
      </v-row>

      <v-data-table
        :headers="headers"
        :items="all"
        :items-per-page="5"
        :sort-by="['createdAt']"
        :sort-desc="[true]"
        :search="query"
        :custom-filter="searchByName"
        :class="{loading: loading}"
      >
        <template v-slot:item.state="{ item }">
          <span v-if="item.state === 'CREATED'">
            <v-icon>mdi-timer-sand</v-icon>Created
          </span>
          <span v-if="item.state === 'STARTED'">
            <v-icon>mdi-run</v-icon>Running
          </span>
          <span v-if="item.state === 'FINISHED'">
            <v-icon>mdi-flag-checkered</v-icon>Finished
          </span>
          <span v-if="item.state === 'ABORTED'">
            <v-icon>mdi-cancel</v-icon>Aborted
          </span>
          <span v-if="item.state === 'FAILED'">
            <v-icon>mdi-alert</v-icon>Failed
          </span>
        </template>

        <template v-slot:item.action="{ item }">
          <!-- Only if job is done: provide download -->
          <action-button v-if="item.state === 'FINISHED'"
              :href="item.pdbUrl"
              icon="mdi-download-circle"
              color="green"
          >
            <span>Download PDB file (monomer structure)</span>
          </action-button>
        </template>

        <template v-slot:no-data>
          <template v-if="loading"><LoadingIndicator /> Loading...</template>
          <template v-if="!loading">No jobs available</template>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
/* BEGIN -- Could be refactored into generic list component */

/* Add some margin to the state icon */
table .v-icon {
  margin-right: 0.2em;
}

/* Remove margin of card since main content is a data-table which has already its own margin in cells */
.v-card__text {
  padding: 0;

  .filter {
    margin: 0;
    padding: 0 16px;
  }
}

/* Reduce the padding-right of the last column because the action buttons would otherwise look as if they had some margin */
.v-data-table ::v-deep td:last-child {
  padding-right: 8px; /* instead of 16px */
}

.loading {
  opacity: .5;
}
/* END -- Could be refactored into generic list component */
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoadingIndicator from '../utils/LoadingIndicator.vue'
import ActionButton from '../utils/ActionButton.vue'

export default {
  components: {
    LoadingIndicator,
    ActionButton
  },
  data() {
    return {
      query: '',
      headers: [
        { text: 'Name', value: 'name', align: 'left', width: '35%' },
        { text: 'Method', value: 'method', width: '20%' },
        { text: 'State', value: 'state', width: '10%' },
        { text: 'Created At', value: 'createdAt' },
        { text: 'Actions', value: 'action', align: 'right', sortable: false }
      ]
    }
  },
  methods: {
    ...mapActions('monomers', ['load', 'delete']),
    create() {
      this.$router.push({ path: '/monomers' })
    },
    edit(id) {
      this.$router.push({ path: `/monomers/${id}` })
    },
    searchByName(value, search, item) {
      return (
        item &&
        search != null &&
        item.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
      )
    }
  },
  computed: {
    ...mapGetters('monomers', ['all', 'loading']),
    hasItems() {
      return Object.keys(this.all).length > 0
    }
  },
  created() {
    this.load()
  }
}
</script>
