import Vue from 'vue'
import restClient from '../../lib/restClient'

export default {
  namespaced: true,
  state: {
    loading: true,
    filter: {
      name: null,
      createdByMe: false
    },
    // TODO: pagination
    femJobs: {}
  },
  getters: {
    loading(state) {
      return state.loading
    },
    getFilter(state) {
      return state.filter;
    },
    all(state) {
      let configs = []
      for (const id in state.femJobs) {
        configs.push(state.femJobs[id])
      }
      return configs
    },
    getById: state => id => {
      return state.femJobs[id]
    }
  },
  mutations: {
    loading(state, loading) {
      Vue.set(state, 'loading', loading)
    },
    filter(state, filter) {
      Vue.set(state, 'filter', filter);
    },
    filterProperty(state, changeWithinFilter) {
      for (const key in changeWithinFilter)
        Vue.set(state.filter, key, changeWithinFilter[key]);
    },
    clear(state) {
      Vue.set(state, 'femJobs', {})
    },
    add(state, femJob) {
      Vue.set(state.femJobs, femJob.id, femJob)
    },
    del(state, id) {
      Vue.delete(state.femJobs, id)
    }
  },
  actions: {
    async load({ commit, state }) {
      commit('loading', true)
      const filterSnapshot = JSON.stringify(state.filter)
      // Pagination: Currently, although the REST endpoint already supports pagination, we still implement pagination client-side, so we need to get all results that match the filter
      const params = {
        page: 0,
        size: 2000 // max page size supported by Spring Boot backend
      }
      // Filter:
      if (state.filter.name !== null && state.filter.name !== '') {
        params.name = state.filter.name
      }
      if (state.filter.createdByMe) {
        params.createdBy = 'me'
      }
      let res = await restClient.get(`/v1/fem-jobs/search`, { params })
      // Abort processing result if there has been another call to load() with a different filter in the meantime
      if (JSON.stringify(state.filter) === filterSnapshot) {
        if (res.data && res.data.content) {
          commit('clear')
          for (const femJob of res.data.content) {
            commit('add', femJob)
          }
        }
        commit('loading', false)
      }
    },
    async changeFilter({ commit, dispatch }, changeFilter) {
      commit('filterProperty', changeFilter)
      dispatch('load')
    },
    async create({ commit }, femJob) {
      let res = await restClient.post(`/v1/fem-jobs`, femJob, {
        params: {
          external: window._eureqa_external
        }
      })
      commit('add', res.data)
    },
    terminate(_, id) {
      return restClient.post(`/v1/fem-jobs/${id}/terminate`)
    },
    restart(_, id) {
      return restClient.post(`/v1/fem-jobs/${id}/restart`, null, {
        params: {
          external: window._eureqa_external
        }
      })
    },
    async delete({ commit }, id) {
      await restClient.delete(`/v1/fem-jobs/${id}`)
      commit('del', id)
    }
  }
}
