<template>
  <v-card class="elevation-12">
    <v-toolbar dark flat>
      <v-toolbar-title>Change Your Password</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-alert type="success" v-if="success"
        >Your password has been changed.</v-alert
      >
      <v-alert type="error" v-if="error"
        >An error has occured, please check your passwords.</v-alert
      >
      <v-form ref="form" v-model="valid">
        <v-text-field
          label="Your Current Password"
          name="password"
          prepend-icon="mdi-lock"
          type="password"
          v-model="password"
          hint="At least 8 characters"
          :rules="[rules.required, rules.min]"
        />

        <v-text-field
          label="Your New Password"
          name="newPassword"
          prepend-icon="mdi-lock"
          type="password"
          v-model="newPassword"
          hint="At least 8 characters"
          :rules="[rules.required, rules.min]"
        />

        <v-text-field
          label="Confirm Your New Password"
          name="newPasswordConfirmation"
          prepend-icon="mdi-lock"
          type="password"
          hint="At least 8 characters"
          v-model="newPasswordConfirmation"
          :rules="[rules.required, rules.min]"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" :disabled="!valid" @click="change()"
        >Change Password</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      password: '',
      newPassword: '',
      newPasswordConfirmation: '',
      valid: false,
      success: false,
      error: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters'
      }
    }
  },
  methods: {
    ...mapActions('auth', ['changePassword']),
    async change() {
      this.success = false
      this.error = false

      if (this.newPassword !== this.newPasswordConfirmation) {
        this.error = true
        return
      }

      try {
        await this.changePassword({
          password: this.password,
          newPassword: this.newPassword
        })

        this.success = true
      } catch (e) {
        this.error = true
      }
    }
  }
}
</script>
