<template>
  <v-card>
    <v-toolbar dark flat>
      <v-toolbar-title>
        <v-icon left>mdi-atom</v-icon>
        Create New Monomer
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$router.push({ path: '/' })">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form" v-model="valid" on>
        <v-row>
          <v-col>
            <v-text-field
              label="Name"
              name="name"
              prepend-icon="mdi-pencil"
              type="text"
              v-model="name"
              readonly
              disabled
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Base Monomer"
              name="base"
              prepend-icon="mdi-atom"
              type="text"
              v-model="base"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="1"
            ><MonomerSearch :single-select="true" v-model="base"
          /></v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Sidechain"
              name="sidechain"
              prepend-icon="mdi-molecule"
              type="text"
              v-model="sidechain"
              :rules="[rules.required]"
          /></v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Position"
              name="position"
              prepend-icon="mdi-numeric"
              type="number"
              v-model.number="position"
              :rules="[rules.nonNegativeInteger]"
          /></v-col>
        </v-row>
      </v-form>

      <v-alert type="info" v-if="success">
        Your monomer has been scheduled for computation.
      </v-alert>
      <v-alert type="error" v-if="error">
        Could not save monomer. Please try again later.
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" :disabled="!valid" @click="save()"
        >Create</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MonomerSearch from '../utils/MonomerSearch.vue'

export default {
  components: { MonomerSearch },
  data() {
    return {
      id: null,
      base: '',
      sidechain: '',
      position: 0,
      valid: false,
      success: false,
      error: false,
      rules: {
        required: value => (
          !!value
          || 'This field is required.'
        ),
        nonNegativeInteger: value => (
          (Number.isInteger(value) && value >= 0)
          || 'Enter a non-negative number (atom position, 0-based).'
        )
      }
    }
  },
  methods: {
    ...mapActions('monomers', ['get', 'create']),
    async save() {
      this.success = false
      this.error = false

      if (Array.isArray(this.base)) {
        this.base = this.base[0]
      }

      try {
        await this.create({
          name: `${this.base}_${this.position}_${this.sidechain}`,
          base: this.base,
          sidechain: this.sidechain,
          position: this.position
        })
        this.success = true
      } catch (e) {
        this.error = true
      }
    }
  },
  computed: {
    ...mapGetters('monomers', ['getById']),
    name() {
      if (
        this.base.length === 0 ||
        this.position < 0 ||
        this.sidechain.length === 0
      ) {
        return 'Will be autogenerated'
      }
      return `${this.base}_${this.position}_${this.sidechain}`
    }
  },
  beforeRouteLeave(to, from, next) {
    const canLeave =
      !this.dirty || confirm('You have unsaved changes. Discard?')
    next(canLeave)
  }
}
</script>
