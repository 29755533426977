
function nextStepSize(num) {
    num = Math.abs(num)
    // Let for example num = 123,
    // then digits is 3
    const digits = Math.floor(Math.log10(num)) + 1
    // and bound is 100
    const bound = Math.pow(10, digits - 1)
    // then we find the next prefactor such that it becomes larger
    // with a defined set of allowed prefactors
    for (const prefactor of [2, 5, 10]) {
        if (prefactor * bound > num)
            return prefactor * bound
    }
}

function floorMultiplesOf(num, multiples) {
    return Math.floor(num / multiples) * multiples
}

function ceilMultiplesOf(num, multiples) {
    return Math.ceil(num / multiples) * multiples
}

function niceScale(values, maxNumSteps) {
    const min = Math.min(...values),
          max = Math.max(...values)
     // intentionally start with smaller steps
    let stepSize = nextStepSize((max - min) / maxNumSteps / 10)
    let adjustedMin
    let adjustedMax
    let numSteps
    let safetyCounter = 0
    do {
        stepSize = nextStepSize(stepSize)
        adjustedMin = floorMultiplesOf(min, stepSize)
        adjustedMax = ceilMultiplesOf(max, stepSize)
        numSteps = Math.round((adjustedMax - adjustedMin) / stepSize)
    } while (numSteps > maxNumSteps || ++safetyCounter >= 1000)

    const decimalsInFloat = Math.max(0, -Math.floor(Math.log10(stepSize)))

    return {
        min: adjustedMin,
        max: adjustedMax,
        numSteps,
        stepSize,
        decimalsInFloat
    }
}


export {
    niceScale
}
