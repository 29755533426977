<template>
  <v-dialog v-model="dialog" scrollable max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :fab="!addMode"
        :x-small="!addMode"
        :small="addMode"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon v-if="addMode" small>mdi-plus</v-icon>
        <v-icon v-else>mdi-magnify</v-icon>
        <span v-if="addMode">Add monomer{{multiple ? 's' : ''}}</span>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark flat>
        <v-toolbar-title>Select Monomer{{multiple ? 's' : ''}}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row class="filter">
          <v-text-field
            v-model="query"
            label="Search for monomer by name"
          ></v-text-field>
        </v-row>

        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="all"
          :single-select="!multiple"
          item-key="id"
          show-select
          :search="query"
          :items-per-page="10"
          :sort-by="['name']"
          :custom-filter="searchByName"
        >
          <template v-slot:item.state="{ item }">
            <span v-if="item.state === 'CREATED'">
              <v-icon>mdi-timer-sand</v-icon>Created
            </span>
            <span v-if="item.state === 'STARTED'">
              <v-icon>mdi-run</v-icon>Running
            </span>
            <span v-if="item.state === 'FINISHED'">
              <v-icon>mdi-flag-checkered</v-icon>Finished
            </span>
            <span v-if="item.state === 'ABORTED'">
              <v-icon>mdi-cancel</v-icon>Aborted
            </span>
            <span v-if="item.state === 'FAILED'">
              <v-icon>mdi-alert</v-icon>Failed
            </span>
          </template>
          <template v-slot:no-data>
            <template v-if="loading">
              <LoadingIndicator /> Loading...
            </template>
            <template v-else>
              <span v-if="query">No corresponding monomers found.</span>
              <span v-else>Start typing to search for monomers.</span>
            </template>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="reject()">
          Cancel
        </v-btn>
        <v-btn color="primary" @click="apply()">
          <span v-if="addMode">
            Add
          </span>
          <span v-else>
            Apply
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped>
/* Add some margin to the state icon */
table .v-icon {
  margin-right: 0.2em;
}

/* Remove margin of card since main content is a data-table which has already its own margin in cells */
.v-dialog > .v-card > .v-card__text {
  padding: 0;

  .filter {
    margin: 0;
    padding: 0 16px;
  }
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    value: [String, Array],
    multiple: Boolean,
    addMode: { type: Boolean, default: false }
  },
  data() {
    return {
      id: null,
      query: '',
      selected: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Status', value: 'state', width: '30%' }
      ],
      monomers: [],
      error: false,
      dialog: false,
      rules: {
        required: value => !!value || 'Required.'
      }
    }
  },
  methods: {
    ...mapActions('monomers', ['load']),
    searchByName(value, search, item) {
      return (
        item &&
        search != null &&
        item.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1
      )
    },
    apply() {
      this.dialog = false

      let returnValue = this.selected.map(v => v.name)
      if (!this.multiple) {
        if (returnValue.length >= 1)
          returnValue = returnValue[0]
        else
          returnValue = null
      }

      this.$emit(
        'input',
        returnValue
      )

      this.reset()
    },
    reject() {
      this.dialog = false
      this.reset()
    },
    reset() {
      this.selected = []
    }
  },
  computed: {
    ...mapGetters('monomers', ['all'])
  }
}
</script>
