<template>
  <v-card>
    <v-toolbar dark flat>
      <v-toolbar-title>
        <v-icon left>mdi-cube</v-icon>
        Create New FEM Job
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$router.push({ path: '/' })">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-form ref="nameForm" v-model="nameValid" on>
      <v-card-text>
        <v-row>
          <v-col cols="5">
            <v-text-field
              label="Name"
              name="name"
              type="text"
              v-model="name"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-card-text>
    </v-form>

    <v-divider></v-divider>

    <v-form ref="configForm" v-model="configValid" on>
      <v-card-title>FEM Grid</v-card-title>
      <v-card-subtitle>Define the properties of the FEM grid to be simulated</v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col v-for="(dimension, index) in ['X','Y','Z']" :key="index">
            <v-text-field
                :label="'Grid Size in ' + dimension + '-Dimension'"
                type="number"
                v-model.number="configuration.gridSize[index]"
                :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-title>Materials</v-card-title>
      <v-card-subtitle>Define which materials should be included in the FEM grid</v-card-subtitle>

      <v-card-text>
        <p>
            For each material, enter the ID of the individual job. You find the job ID on the bottom of
            the pop-up window when opening the results of an individual job within a polymer job set.

            Decide which properties you want to use in the FEM calculation, i.e. from which cooling
            simulation (simulation time) and stress-strain simulation (temperature & simulation time)
            the result values will be used.

            Note that FEM jobs are always executed at room temperature, independently from the given
            temperatures given here.

            Finally, set a weight to decide how much the material should be used in the random
            distribution.
        </p>

        <v-row class="mt-6">
          <v-col class="material-list">
            <v-row v-for="(material, index) in configuration.materials" :key="index">
              <v-col>
                <v-text-field
                  :label="'Material ' + (index + 1) + ' (Polymer System Job ID)'"
                  type="text"
                  v-model="material.id"
                  :rules="[rules.required]"
                />
              </v-col>
              <!--v-col
                md="auto"
                class="wrap-button-next-to-text-field"
              >
                <MaterialSearch :multiple="false" v-model="material[0]" />
              </v-col-->

              <v-col xl="2" md="4" sm="6">
                <v-text-field
                  label="Cooling Simulation Time [ns]"
                  type="number"
                  v-model.number="material.coolingSimtime"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col xl="2" md="4" sm="6">
                <v-text-field
                  label="Stress-Strain Simulation Time [ns]"
                  type="number"
                  v-model.number="material.stressSimtime"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col xl="2" md="4" sm="6">
                <v-text-field
                  label="Stress-Strain Temperature [K]"
                  type="number"
                  v-model.number="material.stressTemperature"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col xl="2" md="4" sm="6">
                <v-text-field
                  label="Random Distribution Weight"
                  type="number"
                  v-model.number="material.weight"
                  :rules="[rules.required, rules.weightsSumUpTo1]"
                />
              </v-col>
              <v-col md="auto">
                <v-btn
                  :--disabled="configuration.materials.length == 1"
                  small
                  class="baseline-adjusted-button"
                  @click="deleteMaterial(index)"
                >
                  <v-icon small>mdi-delete</v-icon>
                  Delete material
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer />
          <v-col md="auto" v-if="!weightsSumIsOne()">
            <v-btn small @click="autoAdjustWeights()">
              <v-icon small>mdi-lightbulb</v-icon>
              Auto-adjust weights
            </v-btn>
          </v-col>

          <v-col md="auto">
            <v-btn small @click="addMaterial()">
              <v-icon small>mdi-plus</v-icon>
              Add another material
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <!--v-card-title>
        Summary
      </v-card-title-->

      <v-card-text>
        <v-alert type="info" v-if="success">
          Your job has been scheduled for computation.
        </v-alert>
        <v-alert type="error" v-if="error">
          Could not create job. Please try again later.
        </v-alert>
      </v-card-text>
    </v-form>

    <v-card-actions>
      <v-spacer />

      <v-tooltip top left>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn
                color="primary"
                :disabled="!configValid || !nameValid"
                @click="save()"
            >
              <v-icon>mdi-play</v-icon>
              Create &amp; Run
            </v-btn>
          </div>
        </template>
        <span v-if="!configValid">
          Some parameter seems to be missing or invalid.
        </span>
        <span v-else-if="!nameValid">
          Job name is missing.
        </span>
        <span v-else>
          You're ready to go!
        </span>
      </v-tooltip>
    </v-card-actions>


    <!--pre>{{configAsJsonString()}}</pre-->
  </v-card>
</template>

<style scoped lang="scss">
  /* Compress the appearance of the summary section's content and the card's action buttons */
  .v-form > .v-card__text:last-child > :last-child  {
    /* Reserve space on the right for the (only) action button of the card */
    padding-right: 160px;
  }
  .v-card__actions {
    /* Amount of compression */
    margin-top: -32px;
  }

  /* Compress the padding of each field, which was introduced by wrapping them in v-row + v-col */
  .v-card__text ::v-deep .col {
    padding-top: 0;
    padding-bottom: 0;
  }

  .faint {
    opacity: 0.5;
  }
</style>

<script>
import { mapActions, mapGetters } from 'vuex'


export default {
  components: {
  },
  data() {
    return {
      id: null,
      name: '',
      nameValid: false,
      numberOfJobs: 0,
      selectedMaterials: '',
      selectedCopolymers: [],
      configuration: {  // FIXME: Find a better name. This holds the models for the form inputs.
        gridSize: [6,6,6],
        materials: [
            this.makeEmptyMaterial(0.5),
            this.makeEmptyMaterial(0.5)
        ]
      },
      config: {},  // FIXME: Find a better name. This is the configuration as in REST API.
      configValid: false,
      success: false,
      error: false,
      rules: {
        required: value => !!value || 'This field is required.',
        weightsSumUpTo1: value => this.weightsSum() == 1 || 'Weights do not sum up to 1.'
      },
      debounceTimer: null
    }
  },
  methods: {
    ...mapActions('femJobs', [
      'create',
      'update'
    ]),
    initFromConfig(config) {
      // Convert API structure into object which we use for our form model
      this.configuration = {
        gridSize: config.gridSize,
        materials: Object.entries(config.materials).map(([name, m]) => ({
            id: m.childJob,
            coolingSimtime: m.coolingSimtime,
            stressSimtime: m.stressSimtime,
            stressTemperature: m.stressTemperature,
            weight: config.distributionParams[name]
        }))
      }

      // Create new objects for Vue to apply the new properties properly
      this.configuration = Object.assign({}, this.configuration)
    },
    weightsSum() {
        return this.configuration.materials.map(m => m.weight).reduce((a, b) => a + b)
    },
    weightsSumIsOne() {
        // Test for equality with 1.0 with some fuzzyness (for float representation inaccuracy)
        return Math.abs(this.weightsSum() - 1) < 1e-6
    },
    autoAdjustWeights() {
        const sum = this.weightsSum()
        for (const m of this.configuration.materials)
            m.weight /= sum
    },
    prepareConfig() {
      const result = {
        gridSize: this.configuration.gridSize,
        materials: Object.fromEntries(
          this.configuration.materials.map(m => [
            // key: The name of the material
            m.id,
            // value:
            {
                childJob: m.id,
                coolingSimtime: m.coolingSimtime,
                stressSimtime: m.stressSimtime,
                stressTemperature: m.stressTemperature
            }
          ])
        ),
        distribution: "random",
        distributionParams: Object.fromEntries(
          this.configuration.materials.map(m => [
            // key: The name of the material
            m.id,
            // value: The weights
            m.weight
          ])
        )
      }
      console.log(JSON.stringify(result))
      return result
    },
    configAsJsonString() {
        return JSON.stringify(this.prepareConfig(),null,2)
    },
    addMaterial() {
      this.configuration.materials.push(
        this.makeEmptyMaterial(0)
      )
    },
    makeEmptyMaterial(weight) {
        return {
            id: '',
            coolingSimtime: null,
            stressSimtime: null,
            stressTemperature: null,
            weight: weight
        }
    },
    deleteMaterial(index) {
      this.configuration.materials.splice(index, 1)
    },
    async save() {
      this.success = false
      this.error = false

      try {
        await this.create({
          name: this.name,
          configuration: this.prepareConfig()
        })
        this.success = true
      } catch (e) {
        this.error = true
      }
    }
  },
  computed: {
    ...mapGetters({
      femJobById: 'femJobs/getById',
      childJobById: 'childJobs/getById',
    }),
  },
  async created() {
    let job = 'from-job' in this.$route.query
            ? this.femJobById(this.$route.query['from-job'])
            : undefined

    if (job !== undefined) {
      let config = job.configuration
      this.initFromConfig(config)
    }
  }
}
</script>
