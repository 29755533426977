<template>
  <v-card :loading="loading ? 'secondary' : false">
    <v-toolbar dark flat>
      <v-toolbar-title>Results for Job {{ parentJob.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="downloads($event)">
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-container class="ma-0 pa-0">
        <v-row class="ma-0 pa-0">
          <v-col
            cols="2"
            class="ma-0 pa-0 pt-4"
            style="border-right: 2px solid rgba(0, 0, 0, 0.12)"
          >
            <h5
              class="mx-4 mt-0 mb-0"
              style="
                font-size: 12px;
                line-height: 1.5;
                height: 31px;
                padding-top: 6px;
              "
            >
              Computed property (y-axis)
            </h5>

            <v-list class="key ma-0 pt-0 pb-0" three-line>
              <v-list-item-group v-model="key" class="key">
                <template v-for="(k, index) in keys">
                  <div :key="index">
                    <v-divider></v-divider>
                    <v-list-item :value="k.key" :disabled="!k.available">
                      <v-list-item-content>
                        <v-list-item-title>{{
                          k.simulationTitle
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          >{{ k.computedProperty.title }} [{{
                            k.computedProperty.unit
                          }}]</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </template>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="10" class="ma-0 pa-0 pt-4">
            <v-data-table
              dense
              :hide-default-footer="true"
              :headers="[
                {
                  text: 'Parameter',
                  align: 'begin',
                  value: 'text',
                  sortable: false
                },
                {
                  text: 'Use in plot as ...',
                  align: 'begin',
                  value: 'usage',
                  sortable: false
                },
                {
                  text: 'Select specific value(s)',
                  align: 'begin',
                  value: 'values',
                  sortable: false
                }
              ]"
              :sort="false"
              :items="variations"
            >
              <template v-slot:item.text="{ item }">
                {{ item.text }}
              </template>
              <template v-slot:item.usage="{ item }">
                <v-chip-group
                  v-if="item.values.length > 1"
                  v-model="item.usage"
                >
                  <v-chip value="x-axis">x-axis</v-chip>
                  <v-chip value="colors">color</v-chip>
                  <v-chip value="styles">style</v-chip>
                </v-chip-group>
                <span
                  v-else-if="item.values.length == 1"
                  class="no-usage single-value"
                >
                  &ndash; single value &ndash;
                </span>
                <span v-else class="no-usage no-value">
                  &ndash; not available for {{ key }} &ndash;
                </span>
              </template>
              <template v-slot:item.values="{ item }">
                <v-chip-group multiple v-model="item.selected">
                  <v-chip
                    v-for="(value, index) in item.values"
                    :key="index"
                    :value="value"
                    :class="{
                      'usage-x-axis': item.usage === 'x-axis',
                      'usage-colors': item.usage === 'colors',
                      'usage-styles': item.usage === 'styles',
                      'usage-specific': item.usage === undefined
                    }"
                    :disabled="item.values.length == 1"
                    :style="
                      item.selected.indexOf(value) !== -1
                        ? item.usage == 'colors'
                          ? {
                              background:
                                colors[
                                  item.selected.indexOf(value) % colors.length
                                ]
                            }
                          : item.usage == 'styles'
                          ? {
                              'border-style':
                                styles[
                                  item.selected.indexOf(value) % styles.length
                                ].style,
                              'border-width':
                                styles[
                                  item.selected.indexOf(value) % styles.length
                                ].width + 'px',
                              padding:
                                '0 ' +
                                (8 -
                                  styles[
                                    item.selected.indexOf(value) % styles.length
                                  ].width) +
                                'px'
                            }
                          : {}
                        : {}
                    "
                    >{{ value }}</v-chip
                  >
                </v-chip-group>
              </template>
            </v-data-table>
            <v-divider></v-divider>
            <div class="px-4 pt-4" style="height: 300px">
              <apexchart
                v-if="selectedForXAxis != -1"
                type="line"
                height="100%"
                :options="chartOptions"
                :series="chartSeries"
              ></apexchart>
              <div v-else style="padding: 20px 0">
                <p>No chart can be plotted for the above configuration.</p>
                <p>
                  From the list of parameters, select at least one for the
                  <strong>x-axis</strong>.
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.v-data-table ::v-deep td {
  white-space: nowrap;
}
.v-card__text {
  padding: 0;
}
.v-list.key,
.v-list-item-group.key,
.v-list-item-group.key .v-list-item:last-child {
  border-bottom-left-radius: 4px;
}
.v-list-item-group.key .v-list-item {
  height: 128px;
  padding-right: 2em;
}
.v-list-item-group.key .v-list-item__title {
  font-weight: 500;
  font-size: 140%;
  opacity: 0.7;
}
.v-list-item-group.key .v-list-item--disabled {
  opacity: 0.7;
}
.v-list-item-group.key .v-list-item--disabled .v-list-item__subtitle {
  opacity: 0.5;
}
.no-usage {
  opacity: 0.6;
  font-style: italic;
}
.v-chip {
  height: 24px;
  padding: 0 8px;
  margin: -1px 4px -1px 0;
}
.v-chip:not(.v-chip--active) {
  background: rgba(0, 0, 0, 0.08);
}
.v-chip--active:not(.usage-styles) {
  background: rgba(0, 0, 0, 0.8);
  color: rgba(255, 255, 255, 0.8);
}
.v-chip--active.usage-styles {
  background: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.8);
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import { niceScale } from '../utils/niceScale'
import { monomerSpecToString } from '../utils/monomerSpecFormat'

// from apexcharts palette1, but repeated with darker colors
const COLORS = [
  '#008FFB',
  '#00E396',
  '#FEB019',
  '#FF4560',
  '#775DD0',
  '#00487D',
  '#00714B',
  '#7F580C',
  '#802230',
  '#3B2E68'
]
const NO_COLORS = '#444444'

const STYLES = [
  { style: 'solid', width: 2, dashArray: 0 },
  { style: 'dashed', width: 2, dashArray: 6 },
  { style: 'dotted', width: 2, dashArray: 3 },
  { style: 'solid', width: 1, dashArray: 0 },
  { style: 'dashed', width: 1, dashArray: 6 },
  { style: 'dotted', width: 1, dashArray: 3 },
  { style: 'solid', width: 3, dashArray: 0 },
  { style: 'dashed', width: 3, dashArray: 8 },
  { style: 'dotted', width: 3, dashArray: 4 },
  { style: 'dotted', width: 5, dashArray: 4 }
]

const PROP_TITLE = {
  monomer: 'Monomer',
  fibers: 'Number of fibers',
  averageFiberLength: 'Average fiber length',
  dispersity: 'Dispersity (PDI)',
  simTime: 'Simulation time [ns]',
  temp: 'Temperature [K]'
}

const KEYS = [
  {
    key: 'density',
    simulationTitle: 'Density',
    computedProperty: {
      title: 'Volumetric mass density',
      unit: 'g / cm³'
    },
    available: false
  },
  {
    key: 'cooling',
    simulationTitle: 'Cooling',
    computedProperty: {
      title: 'Glass transition temperature',
      unit: 'K'
    },
    available: false
  },
  {
    key: 'stress_strain',
    simulationTitle: 'Stress Strain',
    computedProperty: {
      title: "Young's Modulus",
      unit: 'GPa'
    },
    available: false
  },
  {
    key: 'hardness',
    simulationTitle: 'Hardness',
    computedProperty: {
      title: 'Hardness',
      unit: 'MPa'
    },
    available: false
  }
]

function smartCompare(a, b) {
  // When using sort() with this compare function, it automatically sorts strings
  // lexicographically and numbers numerically.
  // TODO: In the future we might want numbers inside strings to be sorted numerically.
  // e.g. "PET*5" should come before "PET*10".
  return a < b ? -1 : a > b ? 1 : 0
}
function isSmartSorted(array) {
  for (let i = 1; i < array.length; ++i) {
    if (smartCompare(array[i], array[i - 1]) < 0) {
      return false
    }
  }
  return true
}
function smartSort(array) {
  array.sort(smartCompare)
}

export default {
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      // Constants:
      keys: KEYS,
      colors: COLORS,
      styles: STYLES,

      jobId: '', // from route
      key: 'density', // the property which should be plotted
      allResults: [], // computed manually after loading child jobs + when changing "key"
      variations: [], // configuration of the chart; (re-)initialized after updating "allResults"; used as models; computed properties "chartSeries" and "chartData" depend on this
      previousVariations: [], // updated in watcher of "variations"
      chartOptions: {
        chart: {
          type: 'line',
          toolbar: {
            show: false
          },
          animations: {
            enabled: false
            // animateGradually: false
          }
        },
        markers: { size: 4, hover: { sizeOffset: 2 } },
        legend: { show: false },
        grid: {
          padding: {
            left: 20,
            right: 10
          },
          borderColor: 'rgba(0,0,0,0.1)',
          row: {
            colors: ['rgba(0,0,0,0.05)', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('childJobs', ['load', 'terminate', 'restart']),
    ...mapActions({ loadJobs: 'jobs/load' }),

    downloads() {
      var element = document.createElement('a')
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(this.allResults))
      )
      element.setAttribute('download', 'result.json')

      element.style.display = 'none'
      document.body.appendChild(element)

      element.click()

      document.body.removeChild(element)
    },

    loadChildJobs() {
      if (this.jobId != '') {
        // Also load (parent) jobs if the current jobId is not found in the store
        // (Needed for example when starting the browser tab in this view)
        if (this.jobById(this.jobId) === undefined) {
          this.loadJobs()
        }
        this.load(this.jobId)
      }
    },
    _updateAvailableKeys() {
      for (const k of this.keys) {
        k.available = this.all.some((childJob) =>
          childJob.results.some((resultItem) => resultItem.key == k.key)
        )
      }
    },
    _updateAllResults() {
      const results = []
      for (const childJob of this.all) {
        for (const resultItem of childJob.results) {
          if (resultItem.key == this.key) {
            results.push({
              value: resultItem.value,
              properties: {
                monomer: monomerSpecToString(
                  childJob.configuration.monomer
                ).replaceAll(' ', ''),
                fibers: childJob.configuration.fibers,
                averageFiberLength: childJob.configuration.averageFiberLength,
                dispersity: childJob.configuration.dispersity,
                simTime: resultItem.simTime,
                temp: resultItem.temp
              }
            })
          }
        }
      }
      this.allResults = results // TODO: do we need Vue.$set?
    },
    _initializeVariations() {
      // Find the set of values used for each property
      const valuesForEachProperty = {
        monomer: [],
        fibers: [],
        averageFiberLength: [],
        dispersity: [],
        simTime: [],
        temp: []
      }
      const properties = Object.keys(valuesForEachProperty)
      for (const result of this.allResults) {
        for (const property of properties) {
          if (
            result.properties[property] !== null &&
            valuesForEachProperty[property].indexOf(
              result.properties[property]
            ) == -1
          ) {
            valuesForEachProperty[property].push(result.properties[property])
          }
        }
      }
      // Sort the values of each property
      for (const property of properties) {
        smartSort(valuesForEachProperty[property])
      }
      // Filter out single-value properties, as they are no "variation"
      const valuesForEachVaryingProperty = Object.fromEntries(
        Object.entries(valuesForEachProperty).filter(
          ([, values]) => values.length > 1
        )
      )
      // Build variations
      this.variations = Object.entries(valuesForEachProperty).map(
        ([property, values]) => {
          // This will select 'x-axis' for the first, 'colors' for the second
          // and 'styles' for the third property, undefined for all others.
          const propertyIndex = Object.keys(
            valuesForEachVaryingProperty
          ).indexOf(property)
          const usage = ['x-axis', 'colors', 'styles'][propertyIndex] // Intentionally allow out-of-bound access (see above)
          return {
            name: property,
            text: PROP_TITLE[property],
            usage: usage,
            values: values,
            selected: usage === undefined ? [values[0]] : values
          }
        }
      )
      // Deep copy current variations for the "previous" ones to detect changes in watcher
      this.previousVariations = JSON.parse(JSON.stringify(this.variations))
    },
    _makeVariationsConsistent() {
      // Find out if there was an item selected in the "usage" column (which wasn't selected before)
      let newUsageType = undefined
      let newUsageIndex = undefined
      for (let index = 0; index < this.variations.length; ++index) {
        const v = this.variations[index]
        const p = this.previousVariations[index]
        if (v.usage !== undefined && v.usage !== p.usage) {
          newUsageType = v.usage
          newUsageIndex = index
        }
      }
      // If this is the case, and if that row was previously in "specific value" mode, then select all values.
      if (
        newUsageIndex !== undefined &&
        this.previousVariations[newUsageIndex].usage === undefined
      ) {
        const v = this.variations[newUsageIndex]
        v.selected = [...v.values]
      }
      // Make sure that no usage type is selected multiple times
      if (newUsageIndex !== undefined) {
        // Helper function to find index which has a specific usage type
        const findIndexOfUsage = function (variations, usage) {
          for (let index = 0; index < variations.length; ++index) {
            if (variations[index].usage == usage) return index
          }
          return undefined
        }
        let previousIndex = findIndexOfUsage(
          this.previousVariations,
          newUsageType
        )
        if (previousIndex !== undefined)
          this.variations[previousIndex].usage = undefined
      }
      // Make sure that in rows with "specific value" mode, only one item is selected.
      for (let index = 0; index < this.variations.length; ++index) {
        const v = this.variations[index]
        const p = this.previousVariations[index]
        if (v.usage === undefined && v.selected.length > 1) {
          // If the row was previously in a usage mode,
          // keep the selection of the first selected item.
          if (p.usage !== undefined) {
            v.selected = [v.selected[0]]
          }
          // If the row was previously also in "specific value" mode, that means, that
          // the user clicked on a different value (wants to change it)
          // => We need to keep the selection of the second selected item.
          else {
            v.selected = [v.selected[1]]
          }
        } else if (v.selected.length === 0) {
          // If the user deselects everything, undo the deselection, since one item has
          // to be selected all the time.
          v.selected = [...p.selected]
        }
      }
      // For x-axis, make sure that the selection is sorted.
      for (const v of this.variations) {
        if (v.usage == 'x-axis') {
          // First check if it is alreay sorted, because if we would just sort it,
          // we would trigger an infinite loop because variations is being watched.
          if (!isSmartSorted(v.selected)) {
            smartSort(v.selected)
          }
        }
      }
    },
    _findVariationsIndexSelectedFor(usage) {
      for (let index = 0; index < this.variations.length; ++index) {
        if (this.variations[index].usage == usage) {
          return index
        }
      }
      return -1
    }
  },
  computed: {
    ...mapGetters('childJobs', ['all', 'loading']),
    ...mapGetters({ jobById: 'jobs/getById' }),
    parentJob: function () {
      return this.jobById(this.jobId)
    },
    selectedForXAxis: function () {
      return this._findVariationsIndexSelectedFor('x-axis')
    },
    selectedForColors: function () {
      return this._findVariationsIndexSelectedFor('colors')
    },
    selectedForStyles: function () {
      return this._findVariationsIndexSelectedFor('styles')
    },
    chartSeries: function () {
      if (this.selectedForXAxis == -1) {
        return []
      }
      // Filter the results by all properties, where only one value is selected (or exists at all)
      let filtered = this.allResults
      for (const v of this.variations) {
        if (v.selected.length == 1) {
          filtered = filtered.filter(
            (r) => r.properties[v.name] == v.selected[0]
          )
        }
      }
      // Generate one data set for each color
      const xaxis_values = this.variations[this.selectedForXAxis].selected
      const color_values =
        this.selectedForColors == -1
          ? [undefined]
          : this.variations[this.selectedForColors].selected
      const style_values =
        this.selectedForStyles == -1
          ? [undefined]
          : this.variations[this.selectedForStyles].selected
      const color_style_value_combinations = color_values.flatMap(
        (color_value) =>
          style_values.map((style_value) => [color_value, style_value])
      )
      const chartSeries = color_style_value_combinations.map(
        ([color_value, style_value]) => {
          var filteredThisSeries = filtered

          // If colors (multiple series) are being used, furthermore filter the result set by the value of the current color
          if (color_value !== undefined) {
            filteredThisSeries = filteredThisSeries.filter(
              (r) =>
                r.properties[this.variations[this.selectedForColors].name] ==
                color_value
            )
          }
          // ... same with style
          if (style_value !== undefined) {
            filteredThisSeries = filteredThisSeries.filter(
              (r) =>
                r.properties[this.variations[this.selectedForStyles].name] ==
                style_value
            )
          }

          // Make sure that there is one data point for each value on the x-axis.
          // Fill up with nulls if this doesn't fit (which can occur for example if there was an error in one computation).
          const seriesDataPoints = xaxis_values.map((xaxis_value) => {
            // Search for the result which has the x-axis value.
            const found = filteredThisSeries.filter(
              (r) =>
                r.properties[this.variations[this.selectedForXAxis].name] ==
                xaxis_value
            )
            return found.length ? found[0].value : null
          })

          // If colors or styles (= multiple series) are being used, include the properties and their values in the series name.
          // Otherwise, set the name to something generic.
          const seriesClassifiers = []
          if (this.selectedForColors !== -1)
            seriesClassifiers.push(
              this.variations[this.selectedForColors].text + ' = ' + color_value
            )
          if (this.selectedForStyles !== -1)
            seriesClassifiers.push(
              this.variations[this.selectedForStyles].text + ' = ' + style_value
            )
          const seriesName =
            seriesClassifiers.length == 0
              ? 'Results'
              : seriesClassifiers.join(', ')

          return {
            name: seriesName,
            data: seriesDataPoints
          }
        }
      )
      return chartSeries
    }
  },
  watch: {
    // After childJob have been loaded and when changing the key (property to be plotted),
    // we trigger computation of the result set and variaton.
    // Using the automatic "Vue" way would trigger a recomputation of this stuff too many times.
    all: function () {
      this._updateAllResults()
      this._initializeVariations()
      this._updateAvailableKeys()
    },
    key: function () {
      this._updateAllResults()
      this._initializeVariations()
    },

    // The clickable "chips" in the template have models in the variations, whose rule of
    // "what / which combinations can be selected" are too complated for v-chip-groups.
    // Instead, we modify the state after changing such that it becomes consistent again.
    variations: {
      deep: true,
      handler: function () {
        this._makeVariationsConsistent()
        // Deep copy current variations for the "previous" ones to detect changes for next time
        this.previousVariations = JSON.parse(JSON.stringify(this.variations))
      }
    },

    // Adjust the chartOptions depending on the chartSeries, but not all of them,
    // so we use a watcher to update some stuff instead of a computed property.
    chartSeries: function () {
      // Only proceed if something is selected for the x-axis (as only then we can do it properly)
      if (this.selectedForXAxis == -1) return

      const computedProperty = KEYS.filter((k) => k.key == this.key)[0]
        .computedProperty

      // Compute y axis scaling
      const allValues = this.chartSeries
        .flatMap(({ data }) => data)
        .filter((v) => v !== null)
      const { min, max, numSteps, decimalsInFloat } = niceScale(allValues, 8)

      // Generate array of strokes (colors and line styles)
      const colors =
        this.selectedForColors == -1
          ? [NO_COLORS]
          : this.variations[this.selectedForColors].selected.map(
              (_, i) => COLORS[i % COLORS.length]
            )
      const styles =
        this.selectedForStyles == -1
          ? [STYLES[0]]
          : this.variations[this.selectedForStyles].selected.map(
              (_, i) => STYLES[i % STYLES.length]
            )
      const series = colors.flatMap((color) =>
        styles.map((style) => {
          return {
            color: color,
            width: style.width,
            dashArray: style.dashArray
          }
        })
      )

      // Combine the array of strokes to a single stroke which has an array for each property
      // (this is the format apexchart expects)
      const colorsReduced = series.map((s) => s.color)
      const strokesReduced = {
        width: series.map((s) => s.width),
        dashArray: series.map((s) => s.dashArray)
      }

      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          categories: this.variations[this.selectedForXAxis].selected,
          title: {
            text: this.variations[this.selectedForXAxis].text
          }
        },
        yaxis: {
          title: {
            text: computedProperty.title + ' [' + computedProperty.unit + ']'
          },
          decimalsInFloat: decimalsInFloat,
          min: min,
          max: max,
          tickAmount: numSteps
        },
        tooltip: {
          x: {
            show: false
          },
          y: {
            // Format the (result) value of data points inside the tooltip
            formatter: (value) => {
              if (value !== null) {
                const significantDigits = 6
                return value.toPrecision(significantDigits)
              }
            }
          }
        },
        colors: colorsReduced, // see above
        stroke: strokesReduced // see above
      }
    }
  },
  async created() {
    this.jobId = this.$route.params.id
    this.loadChildJobs()
  }
}
</script>
