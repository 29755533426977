<template>
    <v-container>
        <CreateMonomer />
    </v-container>
</template>

<script>
import CreateMonomer from '../components/create-monomer/CreateMonomer.vue'

export default {
    components: {
        CreateMonomer
    }
}
</script>
