import Auth from '@aws-amplify/auth'
import Amplify from '@aws-amplify/core'

const config = {
  userPoolId: process.env.VUE_APP_USER_POOL_ID,
  identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
  userPoolWebClientId: process.env.VUE_APP_CLIENT_ID,
  region: process.env.VUE_APP_REGION,
  authenticationFlowType: 'USER_SRP_AUTH'
}

Amplify.configure({ Auth: config })

export default {
  async isLoggedIn() {
    return Auth.currentAuthenticatedUser()
  },
  async getUser() {
    try {
      let user = await Auth.currentAuthenticatedUser()
      return {
        name: user.attributes.name,
        institution: user.attributes['custom:institution'],
        position: user.attributes['custom:position'],
        email: user.attributes.email,
        username: user.attributes.email,
        id: user.attributes.sub
      }
    } catch {
      return {}
    }
  },
  async getToken() {
    try {
      let session = await Auth.currentSession()
      return session.getIdToken().getJwtToken()
    } catch {
      return null
    }
  },
  async signIn(credentials) {
    return new Promise((resolve, reject) => {
      Auth.signIn(credentials.username, credentials.password)
        .then(user => {
          if (
            user.challengeName === 'FORCE_CHANGE_PASSWORD' ||
            user.challengeName === 'NEW_PASSWORD_REQUIRED'
          ) {
            reject('SET_NEW_PASSWORD')
          } else {
            resolve(user)
          }
        })
        .catch(reject)
    })
  },
  async setNewPassword(credentials) {
    return new Promise((resolve, reject) => {
      Auth.signIn(credentials.username, credentials.password)
        .then(user => {
          if (
            user.challengeName === 'FORCE_CHANGE_PASSWORD' ||
            user.challengeName === 'NEW_PASSWORD_REQUIRED'
          ) {
            user.completeNewPasswordChallenge(credentials.newPassword, null, {
              onSuccess: () => {
                Auth.signIn({
                  username: credentials.username,
                  password: credentials.newPassword
                })
                  .then(resolve)
                  .catch(reject)
              },
              onFailure: reject
            })
          } else {
            resolve(user)
          }
        })
        .catch(reject)
    })
  },
  async signOut() {
    return Auth.signOut({ global: true })
  },
  async signUp(data) {
    return Auth.signUp({
      username: data.username,
      password: data.password,
      attributes: {
        name: data.attributes.name,
        'custom:institution': data.attributes.institution,
        'custom:position': data.attributes.position,
        'custom:reason': data.attributes.reason
      }
    })
  },
  async confirmSignUp(data) {
    return Auth.confirmSignUp(data.username, data.code)
  },
  async resendSignUp(username) {
    return Auth.resendSignUp(username)
  },
  async forgotPassword(username) {
    return Auth.forgotPassword(username)
  },
  async forgotPasswordSubmit(data) {
    return Auth.forgotPasswordSubmit(data.username, data.code, data.password)
  },
  changePassword(data) {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(user, data.password, data.newPassword)
        })
        .then(resolve)
        .catch(reject)
    })
  },
  updateUserAttributes(data) {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          Auth.updateUserAttributes(user, {
            name: data.name,
            email: data.email,
            'custom:institution': data.institution,
            'custom:position': data.position
          })
            .then(() => {
              // FIXME when https://github.com/aws-amplify/amplify-js/issues/2534 is resolved
              Auth.currentAuthenticatedUser({ bypassCache: true })
                .then(user => {
                  resolve(user)
                })
                .catch(reject)
            })
            .catch(reject)
        })
        .then(resolve)
        .catch(reject)
    })
  },
  verifyEmail(code) {
    return new Promise((resolve, reject) => {
      Auth.verifyCurrentUserAttributeSubmit('email', code)
        .then(resolve)
        .catch(reject)
    })
  }
}
