<template>
  <div>
    <v-container>
      <Monomers />
    </v-container>
    <v-container>
      <Jobs />
    </v-container>
    <v-container>
      <FemJobs />
    </v-container>
  </div>
</template>

<script>
import Monomers from '../components/job-lists/ListMonomers.vue'
import Jobs from '../components/job-lists/ListJobs.vue'
import FemJobs from '../components/job-lists/ListFemJobs.vue'

export default {
  components: {
    Monomers,
    Jobs,
    FemJobs
  }
}
</script>
