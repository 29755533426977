<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>404 - Four Oh Four</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>Oh No! We couldn't find this page :-(</v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>
