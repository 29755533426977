import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    //dark: true,

    options: { 
      customProperties: true 
    },
    
    themes: {
      dark: {
        background: '#555',
        sheet: '#444',
        title: '#333',

        primary: '#eee',
        secondary: '#f90'
      },
      light: {
        background: '#bbb',
        sheet: '#eee',
        title: '#444',

        primary: '#111',
        secondary: '#f90'
      }
    }
  }
})
