<template>
    <v-dialog v-model="visible" scrollable max-width="700px">
        <v-card>
            <v-toolbar dark flat>
                <v-toolbar-title>
                    Results of "{{ data.name }}"
                </v-toolbar-title>
            </v-toolbar>

            <DialogJobResultDetails v-model="detailsDialog" :data="{ job: data, result: resultDetails }" />

            <v-card-text v-if="hasAnyResults" class="results">
                <v-expansion-panels accordion multiple>
                    <template v-for="(section,key) of resultSections">
                        <v-expansion-panel v-if="section.results.length > 0" v-bind:key="key">
                            <v-expansion-panel-header>{{section.header}}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-data-table dense
                                    :hide-default-footer="section.results.length < 10"
                                    :headers="[ ...section.extraColumnsBeforeResult,
                                                {
                                                    text: section.valueColumnHeader,
                                                    align: 'end',
                                                    value: 'value',
                                                    sortable: section.results.length > 1
                                                },
                                                ...section.extraColumnsAfterResult
                                            ]"
                                    :items="section.results"
                                >
                                    <template v-slot:item.value="{ item }">
                                        <span v-if="item.value" class="value">
                                            {{ item.value }}
                                        </span>
                                        <span v-else class="no-value">
                                            <v-icon small color="red">mdi-close-octagon</v-icon>
                                            <abbr :title="item.error.message ? item.error.message : 'Unknown reason'">
                                                Error
                                            </abbr>
                                        </span>
                                    </template>
                                    <template v-slot:item.secondaryValue="{ item }">
                                        <span v-if="item.secondaryValue" class="value">
                                            {{ item.secondaryValue.value }}
                                        </span>
                                    </template>
                                    <template v-slot:item.details="{ item }">
                                        <action-button icon="mdi-dots-horizontal-circle" color="gray" @click="showDetails(item)">
                                            Show details
                                        </action-button>
                                    </template>
                                    <template v-slot:item.trustworthiness="{ item }">
                                        <abbr v-if="item.trustworthiness" :title="describeTrustworthiness(item.trustworthiness)">
                                            <v-icon v-if="item.trustworthiness.value < item.trustworthiness.threshold" small color="orange">mdi-alert</v-icon>
                                            <v-icon v-else small color="green">mdi-check</v-icon>
                                        </abbr>
                                    </template>
                                </v-data-table>

                                <v-row v-if="section.plot">
                                    <v-col cols="*">
                                        <p>Plot for fitting the glass transition temperature (for all simtimes):</p>
                                        <img width="100%" :src="section.plot.url">
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </template>
                </v-expansion-panels>
            </v-card-text>

            <v-card-text v-else-if="isChildJob">
                <p>
                    <b>This job doesn't have any results.</b>
                </p>
                <p>
                    This can have several reasons:
                    <ul>
                        <li>The job failed. In the future, we will show some error message here instead of the results or this text.</li>
                        <li>The job succeeded but didn't produce results due to some other technical issues.</li>
                        <li>The job succeeded but has been run prior to Phase 4 when we enabled storing results in the database.</li>
                    </ul>
                </p>
                <p>
                    However, you can still try to download the packaged result files using the button below.
                </p>
            </v-card-text>

            <v-card-text v-else>
                <p>
                    <b>Displaying job results is currently only possible via the child jobs.</b>
                </p>
                <p>
                    To do this, close this dialog, click on the job's name and then click on the results icon of one of the child jobs to view its results.
                </p>
                <p>
                    However, in this dialog you can still download the packaged result files using the button below.
                </p>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <pre class="job-id">ID: {{ data.id }}</pre>

                <v-spacer></v-spacer>

                <v-btn text @click="visible = false">
                    Close
                </v-btn>
                <v-btn :href="data.resultUrl" color="primary">
                    <v-icon>mdi-download</v-icon>Download
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style lang="scss" scoped>
.v-dialog > .v-card > {
    .v-card__text {
        padding: 16px;
    }
    .v-card__title,
    .v-card__actions {
        padding-left: 16px;
    }
    .v-card__actions > .job-id {
        font-size: 60%;
        font-weight: normal;
        opacity: 0.5;
    }
}

/* Put the expansion panels inside the card's content without margins
   to realize a "flat" look of the expansion panels */
.v-dialog--scrollable > .v-card > {
    .v-card__text.results {
        padding: 0;
    }
    .v-card__text.results > .v-expansion-panels {
        margin: 0;
        border-radius: 0;
    }

    .v-card__text.results > .v-expansion-panels > {
        .v-expansion-panel::before {
            display: none;
        }
        .v-expansion-panel:hover {
            background: rgba(#808080, 0.1);
        }
        .v-expansion-panel--active > .v-expansion-panel-header--active {
            font-weight: 700;
        }

        /* Also adjust the expansion panels internal horizontal paddings
        to those of the card's default paddings (16px instead of 24px) */
        .v-expansion-panel > {
            .v-expansion-panel-header
            ,.v-expansion-panel-content ::v-deep .v-expansion-panel-content__wrap {
                padding-left: 16px;
                padding-right: 16px;

                /* Undo padding of content which by itself adds again padding. */
                > .v-data-table {
                    margin-left: -16px;
                    margin-right: -16px;
                    max-width: none;

                    /* Avoid wrapping of headers */
                    th {
                        white-space: nowrap;
                    }
                    th:not(:last-child),
                    td:not(:last-child) {
                        padding-right: 0;
                    }
                }
            }
        }
    }
}
</style>

<script>
import ActionButton from '../utils/ActionButton.vue'
import DialogJobResultDetails from './DialogJobResultDetails.vue'

export default {
    components: {
        ActionButton,
        DialogJobResultDetails
    },
    props: {
        value: Boolean,
        data: Object
    },
    data() {
        return {
            resultSections: {
                density: this._makeResultSection({
                    header: 'Density',
                    valueColumnHeaderPrefix: 'Density',
                    simtemp: true
                }),
                cooling: this._makeResultSection({
                    header: 'Cooling',
                    valueColumnHeaderPrefix: 'Glass-Trans. Temp.',
                    simtime: true,
                    properties: [
                        { header: "T_a [K]", key: "T_a" },
                        { header: "T_b [K]", key: "T_b" }
                    ],
                    details: true,
                    trustworthiness: r => {
                        const R2a = r.properties.R_squared_a.value
                        const R2b = r.properties.R_squared_b.value
                        return {
                            value: R2a * R2b,
                            threshold: 0.9,
                            components: {
                                "R²a": R2a,
                                "R²b": R2b
                            }
                        }
                    },
                    plot: job => {
                        if (job.glassTransPlotUrl)
                            return {
                                url: this.data.glassTransPlotUrl
                            }
                        else
                            return null
                    }
                }),
                stress_strain: this._makeResultSection({
                    header: 'Stress strain',
                    valueColumnHeaderPrefix: 'Youngs Modulus',
                    simtime: true, simtemp: true,
                    trustworthiness: r => {
                        // Compatibility with old format, where the property
                        // "Youngs_modulus_R_squared" was called just "R_squared".
                        const R2 = r.properties.hasOwnProperty('Youngs_modulus_R_squared')
                            ? r.properties.Youngs_modulus_R_squared.value
                            : r.properties.R_squared.value
                        return {
                            value: R2,
                            threshold: 0.9,
                            components: {
                                "R²": R2
                            }
                        }
                    },
                    secondaryValueColumnHeader: 'Poisson\'s Ratio',
                    secondaryValue: r => {
                        return r.properties.Poissons_ratio
                    }
                }),
                hardness: this._makeResultSection({
                    header: 'Hardness',
                    valueColumnHeaderPrefix: 'Hardness',
                    simtime: true, simtemp: true
                })
            },

            detailsDialog: false,
            resultDetails: {}
        }
    },
    computed: {
        visible: {
            get() { return this.value },
            set(visible) { this.$emit('input', visible) }
        },
        isChildJob() {
            return 'parentJobId' in this.data
        },
        hasAnyResults() {
            return !(Object.values(this.resultSections).every(section => section.results.length == 0))
        }
    },
    methods: {
        describeTrustworthiness(trustworthiness) {
            if (trustworthiness) {
                return "Trustworthiness: " + trustworthiness.value + "\n"
                    + "   (threshold: " + trustworthiness.threshold + ")\n"
                    + "\n"
                    + "The trustworthiness is being computed based on:\n"
                    + Object.entries(trustworthiness.components).map(([k,v]) => "   " + k + " = " + v).join('\n')
            }
        },
        showDetails(item) {
            console.log(item)
            this.resultDetails = item
            this.detailsDialog = true
        },
        _makeResultSection({
                header,
                valueColumnHeaderPrefix,
                simtime = false, simtemp = false,
                properties = [],
                details = false,
                trustworthiness = null,
                secondaryValue = null,
                secondaryValueColumnHeader = null,
                plot = null
        }) {
            const extraColumnsBeforeResult = []
            const extraColumnsAfterResult = []
            if (simtime) {
                extraColumnsBeforeResult.push({
                    text: 'Simulation Time [ns]',
                    align: 'begin',
                    value: 'simTime',
                })
            }
            if (simtemp) {
                extraColumnsBeforeResult.push({
                    text: 'Temperature [K]',
                    align: 'begin',
                    value: 'temp',
                })
            }
            for (const prop of properties) {
                extraColumnsBeforeResult.push({
                    text: prop.header,
                    align: 'end',
                    value: prop.key,
                })
            }
            if (trustworthiness) {
                extraColumnsAfterResult.push({
                    text: 'Trustworthy',
                    align: 'end',
                    value: 'trustworthiness',
                    sortable: false
                })
            }
            if (secondaryValue) {
                extraColumnsAfterResult.push({
                    text: secondaryValueColumnHeader,
                    align: 'end',
                    value: 'secondaryValue'
                })
            }
            if (details) {
                extraColumnsAfterResult.push({
                    text: 'Details',
                    align: 'end',
                    value: 'details',
                    nonValueColumn: true
                })
            }
            var s = {
                header,
                extraColumnsBeforeResult,
                extraColumnsAfterResult,
                valueColumnHeaderPrefix,
                valueColumnHeader: valueColumnHeaderPrefix, // In _updateResultSections, we append the unit to the header.
                trustworthiness,
                secondaryValue,
                results: [],
                plotFunction: plot
            }
            console.log(s)
            return s;
        },
        _updateResultSections() {
            // Reset all results (contents in sections)
            for (const key in this.resultSections) {
                const section = this.resultSections[key]
                section.results = []
            }

            // Fill sections with results
            for (const r of this.data.results) {
                // Find corresponding section into which this result row belongs
                if (r.key in this.resultSections) {
                    const section = this.resultSections[r.key]
                    // Adjust value column header to also include the unit of measure, which we get from the data
                    if (r.unit) {
                        section.valueColumnHeader = section.valueColumnHeaderPrefix + ' [' + r.unit + ']'
                    }
                    // Remap the properties onto the root level of the result item
                    for (const propertyName in r.properties) {
                        r[propertyName] = r.properties[propertyName].value
                    }
                    // Compute and store the trustworthiness
                    r.trustworthiness = section.trustworthiness ? section.trustworthiness(r) : null
                    // Compute and store the secondaryValue
                    r.secondaryValue = section.secondaryValue ? section.secondaryValue(r) : null
                    // Add to section
                    section.results.push(r)
                }
            }

            // Post-process sections
            for (const key in this.resultSections) {
                const section = this.resultSections[key]

                // Set sortable to true if but only if more than one result exists
                const extraColumns = [
                    ...section.extraColumnsBeforeResult,
                    ...section.extraColumnsAfterResult
                ]
                for (const column of extraColumns) {
                    if (column.nonValueColumn)
                        column.sortable = false
                    else
                        column.sortable = section.results.length > 1
                }

                // Evaluate plotFunction to generate plot object
                if (section.plotFunction) {
                    section.plot = section.plotFunction(this.data)
                } else {
                    section.plot = null
                }
            }
        }
    },
    watch: {
        data() {
            if (this.data.results) {
                this._updateResultSections()
            }
        }
    }
}
</script>
