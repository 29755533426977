<template>
    <div>
        <!-- v-container >
            <JobResults />
        </v-container -->

        <v-container >
            <ListChildJobs />
        </v-container>
    </div>
</template>

<script>
import ListChildJobs from '../components/job-lists/ListChildJobs.vue'
//import JobResults from '../components/result-plots/JobResults.vue'

export default {
    components: {
        ListChildJobs,
        //JobResults
    }
}
</script>
