<template>
    <v-container>
        <CreateFemJob />
    </v-container>
</template>

<script>
import CreateFemJob from '../components/create-fem-job/CreateFemJob.vue'

export default {
    components: {
        CreateFemJob
    }
}
</script>
