import Vue from 'vue'
import restClient from '../../lib/restClient'

export default {
  namespaced: true,
  state: {
    loading: true,
    monomers: {}
  },
  getters: {
    loading(state) {
      return state.loading
    },
    all(state) {
      let monomers = []
      for (const id in state.monomers) {
        monomers.push(state.monomers[id])
      }
      return monomers
    },
    getAsSelectValues(state) {
      return Object.values(state.monomers).map(item => {
        return { text: item.name, value: item.id }
      })
    },
    getById: state => id => {
      return state.monomers[id]
    }
  },
  mutations: {
    loading(state, loading) {
      Vue.set(state, 'loading', loading)
    },
    clear(state) {
      Vue.set(state, 'monomers', {})
    },
    add(state, monomer) {
      Vue.set(state.monomers, monomer.id, monomer)
    },
    remove(state, id) {
      Vue.delete(state.monomers, id)
    }
  },
  actions: {
    async load({ commit }) {
      commit('loading', true)
      let res = await restClient.get(`/v1/monomers`)
      if (res.data) {
        commit('clear')
        for (const monomer of res.data) {
          if (monomer.name[0] !== '.')
            commit('add', monomer)
        }
      }
      commit('loading', false)
    },
    async get(_, id) {
      return restClient.get(`/v1/monomers/${id}`)
    },
    async create({ commit }, monomer) {
      let res = await restClient.post(`/v1/monomers`, {
        name: monomer.name,
        method: 'AM1',
        numberOfAtoms: 0,
        configuration: {
          base: monomer.base,
          sidechain: monomer.sidechain,
          position: monomer.position
        }
      })
      commit('add', res.data)
      return res.data
    },
    async delete({ commit }, id) {
      await restClient.delete(`/v1/monomers/${id}`)
      commit('remove', id)
    }
  }
}
