var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading ? 'secondary' : false}},[_c('v-toolbar',{attrs:{"dark":"","flat":""}},[_c('v-toolbar-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-heat-wave")]),_vm._v(" Polymer Systems in Job "+_vm._s(_vm.parentJob.name)+" ")],1),_c('DialogJobInfo',{attrs:{"data":_vm.childJobInfo},model:{value:(_vm.infoDialog),callback:function ($$v) {_vm.infoDialog=$$v},expression:"infoDialog"}}),_c('DialogJobResults',{attrs:{"data":_vm.childJobResults},model:{value:(_vm.resultsDialog),callback:function ($$v) {_vm.resultsDialog=$$v},expression:"resultsDialog"}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.loadChildJobs()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"filter"},[_c('v-text-field',{attrs:{"label":"Search for job by name","clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('v-snackbar',{attrs:{"timeout":"5000","bottom":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('v-data-table',{class:{loading: _vm.loading},attrs:{"headers":_vm.headers,"items":_vm.all,"items-per-page":10,"sort-by":['createdAt'],"sort-desc":[true],"search":_vm.query,"custom-filter":_vm.searchByName},scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"state"},[(item.state === 'CREATED')?_c('span',[_c('v-icon',[_vm._v("mdi-timer-sand")]),_vm._v("Created ")],1):_vm._e(),(item.state === 'STARTED')?_c('span',[_c('v-icon',[_vm._v("mdi-run")]),_vm._v("Running ("+_vm._s(_vm.progressPercent(item))+") ")],1):_vm._e(),(item.state === 'FINISHED')?_c('span',[_c('v-icon',[_vm._v("mdi-flag-checkered")]),_vm._v("Finished ")],1):_vm._e(),(item.state === 'ABORTED')?_c('span',[_c('v-icon',[_vm._v("mdi-cancel")]),_vm._v("Aborted ")],1):_vm._e(),(item.state === 'QUEUED')?_c('span',[_c('v-icon',[_vm._v("mdi-tray-full")]),_vm._v("Queued ")],1):_vm._e(),(item.state === 'FAILED')?_c('span',[_c('v-icon',[_vm._v("mdi-alert")]),_vm._v("Failed ")],1):_vm._e()])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.startedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.startedAt))+" ")]}},{key:"item.stoppedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.stoppedAt))+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{'duration-running': item.stoppedAt == null}},[_vm._v(" "+_vm._s(_vm.formatDuration(_vm.duration(item)))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.state === 'FINISHED' || item.state === 'FAILED')?_c('action-button',{attrs:{"icon":"mdi-clipboard-list","color":"green"},on:{"click":function($event){return _vm.showJobResults(item)}}},[_c('span',[_vm._v("Results")])]):_vm._e(),_c('action-button',{attrs:{"icon":"mdi-information","color":"blue"},on:{"click":function($event){return _vm.showJobInfo(item)}}},[_c('span',[_vm._v("Info")])]),(item.state === 'FINISHED' || item.state === 'FAILED' || item.state === 'ABORTED')?_c('action-button',{attrs:{"icon":"mdi-refresh-circle","color":"orange"},on:{"click":function($event){return _vm.restartJob(item)}}},[_c('span',[_vm._v("Restart job")])]):_c('action-button',{attrs:{"icon":"mdi-stop-circle","color":"red"},on:{"click":function($event){return _vm.cancelJob(item)}}},[_c('span',[_vm._v("Cancel job")])])]}},{key:"no-data",fn:function(){return [(_vm.loading)?[_c('LoadingIndicator'),_vm._v(" Loading...")]:_vm._e(),(!_vm.loading)?[_vm._v("No jobs available")]:_vm._e()]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }