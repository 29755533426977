
function monomerSpecToString(monomerSpec) {
    return monomerSpec.map(m => {
        if (Array.isArray(m)) {
        return m[0] + '*' + m[1]
        } else {
        return m
        }
    }).join(', ')
}

/*
    Given a comma-separated list of monomers,
    possibly with a multiplicator in the form of '*3',
    e.g.:
        "PET*3, PTDU"
    the function then returns an array-representation,
    e.g.:
        [["PET", 3], "PTDU"]
*/
function monomerSpecFromString(monomerSpecString) {
    return monomerSpecString.split(',')
        .filter(m => m.trim().length > 0)
        .map(m => {
            const parts = m.split('*')
            const monomer = parts[0].trim()
            const multiplicator = parts.length === 1 ? 1 : parseInt(parts[1].trim()) || 1
            return (multiplicator === 1) ? monomer : [monomer, multiplicator]
        })
}

/*
    Given a monomerSpec, determine if it has a multiplier,
    indicating that it is a co-polymer.
*/
const monomerSpecIsCopolymer = (spec) => {
    if (typeof spec === 'string') {
      return false;
    } else if (spec.length === 1) {
      // Technically, this is not a co-polymer, but it has a multiplicator.
      return spec[0].length === 2
        && spec[0][1] > 1;
    } else {
      return true;
    }
  }

export {
    monomerSpecToString,
    monomerSpecFromString,
    monomerSpecIsCopolymer
}
