var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"700px"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(" Results of \""+_vm._s(_vm.data.name)+"\" ")])],1),_c('DialogJobResultDetails',{attrs:{"data":{ job: _vm.data, result: _vm.resultDetails }},model:{value:(_vm.detailsDialog),callback:function ($$v) {_vm.detailsDialog=$$v},expression:"detailsDialog"}}),(_vm.hasAnyResults)?_c('v-card-text',{staticClass:"results"},[_c('v-expansion-panels',{attrs:{"accordion":"","multiple":""}},[_vm._l((_vm.resultSections),function(section,key){return [(section.results.length > 0)?_c('v-expansion-panel',{key:key},[_c('v-expansion-panel-header',[_vm._v(_vm._s(section.header))]),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"dense":"","hide-default-footer":section.results.length < 10,"headers":section.extraColumnsBeforeResult.concat( [{
                                                text: section.valueColumnHeader,
                                                align: 'end',
                                                value: 'value',
                                                sortable: section.results.length > 1
                                            }],
                                            section.extraColumnsAfterResult
                                        ),"items":section.results},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
                                        var item = ref.item;
return [(item.value)?_c('span',{staticClass:"value"},[_vm._v(" "+_vm._s(item.value)+" ")]):_c('span',{staticClass:"no-value"},[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v("mdi-close-octagon")]),_c('abbr',{attrs:{"title":item.error.message ? item.error.message : 'Unknown reason'}},[_vm._v(" Error ")])],1)]}},{key:"item.secondaryValue",fn:function(ref){
                                        var item = ref.item;
return [(item.secondaryValue)?_c('span',{staticClass:"value"},[_vm._v(" "+_vm._s(item.secondaryValue.value)+" ")]):_vm._e()]}},{key:"item.details",fn:function(ref){
                                        var item = ref.item;
return [_c('action-button',{attrs:{"icon":"mdi-dots-horizontal-circle","color":"gray"},on:{"click":function($event){return _vm.showDetails(item)}}},[_vm._v(" Show details ")])]}},{key:"item.trustworthiness",fn:function(ref){
                                        var item = ref.item;
return [(item.trustworthiness)?_c('abbr',{attrs:{"title":_vm.describeTrustworthiness(item.trustworthiness)}},[(item.trustworthiness.value < item.trustworthiness.threshold)?_c('v-icon',{attrs:{"small":"","color":"orange"}},[_vm._v("mdi-alert")]):_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v("mdi-check")])],1):_vm._e()]}}],null,true)}),(section.plot)?_c('v-row',[_c('v-col',{attrs:{"cols":"*"}},[_c('p',[_vm._v("Plot for fitting the glass transition temperature (for all simtimes):")]),_c('img',{attrs:{"width":"100%","src":section.plot.url}})])],1):_vm._e()],1)],1):_vm._e()]})],2)],1):(_vm.isChildJob)?_c('v-card-text',[_c('p',[_c('b',[_vm._v("This job doesn't have any results.")])]),_c('p',[_vm._v(" This can have several reasons: "),_c('ul',[_c('li',[_vm._v("The job failed. In the future, we will show some error message here instead of the results or this text.")]),_c('li',[_vm._v("The job succeeded but didn't produce results due to some other technical issues.")]),_c('li',[_vm._v("The job succeeded but has been run prior to Phase 4 when we enabled storing results in the database.")])])]),_c('p',[_vm._v(" However, you can still try to download the packaged result files using the button below. ")])]):_c('v-card-text',[_c('p',[_c('b',[_vm._v("Displaying job results is currently only possible via the child jobs.")])]),_c('p',[_vm._v(" To do this, close this dialog, click on the job's name and then click on the results icon of one of the child jobs to view its results. ")]),_c('p',[_vm._v(" However, in this dialog you can still download the packaged result files using the button below. ")])]),_c('v-divider'),_c('v-card-actions',[_c('pre',{staticClass:"job-id"},[_vm._v("ID: "+_vm._s(_vm.data.id))]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.visible = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"href":_vm.data.resultUrl,"color":"primary"}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v("Download ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }