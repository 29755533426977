<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Imprint</h1>

        <p>Information in accordance with section 5 TMG</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2>Address</h2>
        <p>
          HQS Quantum Simulations GmbH<br />
          Haid-und-Neu-Straße 7<br />
          D-76131 Karlsruhe
        </p>
        <h2>Contact</h2>
        <p>
          <!--Phone:-->E-Mail: info@quantumsimulations.de<br />
          Internet address: www.quantumsimulations.de
        </p>
        <h2>Represented by</h2>
        <p>Michael Marthaler</p>
        <h2>Register entry</h2>
        <p>
          Entry in Handelsregister<br />
          Register Number: HRB 729051<br />
          Register Court: Amtsgericht Mannheim
        </p>
        <h2>VAT number</h2>
        <p>
          VAT indentification number in accorance with section 27 a of the
          German VAT act<br />
          DE314607258
        </p>
        <h2>
          Person responsible for content in accordance with § 55 Abs. 2 RStV
        </h2>
        <p>
          Michael Marthaler<br />
          HQS Quantum Simulations GmbH<br />
          Haid-und-Neu-Straße 7<br />
          D-76131 Karlsruhe
        </p>
        <h2>Disclaimer</h2>
        <h3>Accountability for content</h3>
        <p>
          The contents of our pages have been created with the utmost care.
          However, we cannot guarantee the contents&#8217; accuracy,
          completeness or topicality. According to statutory provisions, we are
          furthermore responsible for our own content on these web pages. In
          this context, please note that we are accordingly not obliged to
          monitor merely the transmitted or saved information of third parties,
          or investigate circumstances pointing to illegal activity. Our
          obligations to remove or block the use of information under generally
          applicable laws remain unaffected by this as per §§ 8 to 10 of the
          Telemedia Act (TMG).
        </p>
        <h3>Accountability for links</h3>
        <p>
          Responsibility for the content of external links (to web pages of
          third parties) lies solely with the operators of the linked pages. No
          violations were evident to us at the time of linking. Should any legal
          infringement become known to us, we will remove the respective link
          immediately.
        </p>
        <h3>Copyright</h3>
        <p>
          Our web pages and their contents are subject to German copyright law.
          Unless expressly permitted by law (§ 44a et seq. of the copyright
          law), every form of utilizing, reproducing or processing works subject
          to copyright protection on our web pages requires the prior consent of
          the respective owner of the rights. Individual reproductions of a work
          are allowed only for private use, so must not serve either directly or
          indirectly for earnings. Unauthorized utilization of copyrighted works
          is punishable (§ 106 of the copyright law).
        </p>
        <p>Source: www.muster-vorlagen.net</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>
